@charset "utf-8";

@font-face {
  font-family: 'Codec Cold';
  src: local('☺'), url('https://cdn-se.tiger.trade/fonts/CodecCold-Regular.woff') format('woff'),
    url('https://cdn-se.tiger.trade/fonts/CodecCold-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Codec Cold';
  src: local('☺'), url('https://cdn-se.tiger.trade/fonts/CodecCold-Italic.woff') format('woff'),
    url('https://cdn-se.tiger.trade/fonts/CodecCold-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Codec Cold';
  src: local('☺'), url('https://cdn-se.tiger.trade/fonts/CodecCold-Bold.woff') format('woff'),
    url('https://cdn-se.tiger.trade/fonts/CodecCold-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
}

:root {
  font-size: 4.266666vw;
  --main-light: rgba(255, 255, 255, 0.3);
  --main: rgba(255, 255, 255, 0.5);
  --grey: rgba(255, 255, 255, 0.7);
  --accent: #fff;
  --grey-2: #19191d;
  --grey-3: #252528;
  --grey-4: #2c2c2f;
  --dark: #101010;
  --black: #000;
  --green: #0de7b2;
  --green-dark: #17b68f;
  --blue: #7bc0ff;
  --orange: #ff721c;
  --orange-2: #d05c16;
  --red: #ff4016;
  --rose: #ff3f56;
  --bg-main: #0d0d11;
  --bg-grey: rgba(255, 255, 255, 0.05);
  --bg-borders: rgba(255, 255, 255, 0.2);
  --bg-popup: #252528;
  --font-family: 'Codec Cold', 'Gilroy', Arial, -apple-system, 'Open Sans', system-ui, 'sans-serif';
  --light-dark: #1d1b26;
}

/* scrollbar*/
/* for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--accent) transparent;
}

/* for Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--accent);
  border-radius: 3px;
  border: 1px solid var(--bg-main);
}

body {
  margin: 0;
  padding: 0;
  background: var(--bg-main);
  font: normal 0.875rem/1.571428 var(--font-family);
  color: var(--main);
  -webkit-text-size-adjust: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

input,
select,
textarea,
button {
  font: normal 1.5rem/1.5 var(--font-family);
  color: var(--accent);
  outline: none;
  text-align: left;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='color'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='number'],
input[type='range'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='week'],
input[type='file'],
textarea,
select,
.input-placeholder {
  -webkit-appearance: none;
  border-radius: 0;
  box-shadow: none;
  border-style: solid;
  border-color: var(--main);
  border-width: 0 0 0.125rem 0;
  padding: 0 0 0.625rem 0;
  background: none;
  margin: 0;
  width: 100%;
  display: block;
  -webkit-transition-property: color, border-color;
  transition-property: color, border-color;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

input[type='file'] {
  width: 50px;
  height: 50px;
}

.input-file-wrap {
  display: flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  -webkit-text-fill-color: var(--accent);
  transition: background-color 5000s ease-in-out 0s;
}

select:invalid {
  color: var(--main);
}

.download-page .macos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.download-page .macos > svg {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.download-page .macos > .text {
  display: flex;
  flex-direction: column;
}

.download-page .macos > .text > .gradient {
  background: linear-gradient(90deg, #ffc8ad 0%, #b174ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

select:disabled {
  color: var(--main);
}

input:-moz-read-only {
  border-color: transparent;
}

input:read-only {
  border-color: transparent;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
.input-placeholder {
  opacity: 1;
  color: var(--main);
}

input::-moz-placeholder,
textarea::-moz-placeholder,
.input-placeholder {
  opacity: 1;
  color: var(--main);
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
.input-placeholder {
  opacity: 1;
  color: var(--main);
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0.5 !important;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0.5 !important;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0.5 !important;
}

input::-ms-clear {
  display: none;
}

input[type='password' i] {
  font-family: caption;
  color: var(--accent);
}

input::-webkit-input-placeholder {
  font-family: var(--font-family);
}

button::-moz-focus-inner {
  border: 0;
}

textarea {
  resize: none;
}

textarea::-webkit-scrollbar {
  display: none;
}

textarea.autosize {
  height: 3.375rem;
}

.checkbox {
  border: 0.125rem solid var(--accent);
  border-radius: 0.625rem;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-transition: background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.checkbox.radio {
  border-radius: 50%;
}

.checkbox svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.75rem;
  fill: var(--bg-main);
  opacity: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input:checked + .checkbox {
  background-color: var(--accent);
}

input:checked + .checkbox svg {
  opacity: 1;
}

.btn {
  -webkit-appearance: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-decoration: none !important;
  font: bold 0.875rem/0.9375rem var(--font-family);
  letter-spacing: normal;
  border-radius: 0;
  box-shadow: none;
  background: none;
  border-style: solid;
  border-width: 0.125rem;
  border-color: transparent;
  text-transform: uppercase;
  height: 3.75rem;
  position: relative;
  overflow: hidden;
  -webkit-transition-property: color, border-color, background;
  transition-property: color, border-color, background;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.btn-compact {
}

.btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.btn svg {
  -webkit-transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.btn-pic {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 1.25rem;
  height: 1.25rem;
}

.btn-pic svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.btn-pic:not(:first-child) {
  margin-left: 1rem;
}

.btn-pic:not(:last-child) {
  margin-right: 1rem;
}

.btn-primary {
  color: var(--dark);
  border-color: var(--accent);
  background: var(--accent);
}

.btn-primary svg {
  fill: var(--dark);
}

.btn-secondary {
  color: var(--accent);
  border-color: var(--accent);
}

.btn-secondary--no-borders {
  border-color: transparent;
  background-color: var(--light-dark);
}

.btn-secondary svg {
  fill: var(--accent);
}

.btn-orange {
  color: var(--accent);
  border-color: transparent;
  background: var(--orange);
}

.btn-orange svg {
  fill: var(--accent);
}

.btn-grey {
  color: var(--accent);
  background: var(--bg-grey);
}

.btn-grey:hover {
  background: var(--accent);
}

.btn-grey:hover span {
  color: var(--bg-main);
}

.btn-grey svg {
  fill: var(--accent);
}

.check {
  text-align: left;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  margin: -0.25rem 0;
}

.check + .check {
  margin-top: 1.5625rem;
}

.check label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: top;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  padding: 0.28125rem 0 0.28125rem 2.4375rem;
  position: relative;
}

.check .checkbox {
  position: absolute;
  top: 50%;
  left: 0;
  margin: -0.75rem 0;
}

.check.big {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.check.big label {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  padding-left: 2.8125rem;
}

.check .input input,
.check .input select {
  font: inherit;
  letter-spacing: inherit;
}

.check .input {
  margin-top: 1.25rem;
}

.check .input-info {
  margin-top: 0.9375rem;
}

.checks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 -1.5625rem -3.125rem;
}

.checks .check {
  margin: 0 0 1.5625rem 3.125rem !important;
}

.input {
  position: relative;
}

.input select:not(:disabled) {
  cursor: pointer;
}

.subinput-info {
  font-size: 0.75rem;
  line-height: 0.8125rem;
  padding: 0.625rem 0;
  position: relative;
  margin-top: -0.125rem;
}

.subinput-info.red {
  color: var(--accent);
  background: var(--red);
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.select-arr {
  position: absolute;
  top: calc(50% - 0.625rem);
  right: 0;
  pointer-events: none;
  width: 1.25rem;
  height: 1.25rem;
  margin: -0.625rem 0;
}

.select-arr svg {
  width: 100%;
  height: 100%;
  fill: var(--accent);
  -webkit-transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

select:disabled + .select-arr svg {
  fill: var(--main);
}

.input-label {
  font-size: 0.75rem;
  line-height: 0.8125rem;
  text-align: left;
  color: var(--accent);
  display: block;
  margin: 0 0 0.625rem 0;
}

.input-label.big {
  font-size: 1rem;
  line-height: 1.3125rem;
  margin-bottom: 1.875rem;
}

.input-info {
  text-align: left;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 1.25rem 0 0 0;
  color: var(--main);
}

.input-info.big {
  font-size: 1rem;
  line-height: 1.3125rem;
  margin-top: 1.875rem;
}

.form-title {
  color: var(--accent);
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.0625rem;
  margin: 0 0 1.875rem 0;
}

.form-title + .input-info {
  margin: -1.25rem 0 1.875rem 0;
}

.form-row {
  margin: 0 0 3.125rem 0;
}

.form-row.settings-row {
  display: flex;
  flex-direction: column;
}

.form-row h2:first-child,
.form-row .h2:first-child {
  margin-top: 0;
}

.form-row.margin-top {
  margin: 3.125rem 0 0 0;
}

.form-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 -1.875rem -0.9375rem;
}

.form-date-col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0 0 1.875rem 0.9375rem;
  width: 50%;
}

.form-date-col:last-child {
  width: 100%;
}

.form-submit {
  margin: 3.125rem 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form-submit .btn {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 1%;
  flex: 1 1 1%;
}

.submit-info {
  text-align: center;
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 1.4rem;
  text-transform: uppercase;
  letter-spacing: normal;
  color: var(--grey);
  white-space: normal;
  margin: 1.75rem 0 0 0;
}

.submit-info .accent {
  margin-top: 0.625rem;
}

.submit-info a {
  margin-left: 3px;
  font-weight: bold;
}

.submit-info strong {
  margin: 0 3px;
}

.outtaHere {
  overflow: hidden;
  max-width: 100vw;
  position: fixed !important;
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important;
}

header,
nav,
section,
article,
aside,
footer,
menu,
time,
figure,
figcaption,
main {
  display: block;
}

img,
svg,
picture {
  border: 0;
  vertical-align: top;
}

a {
  color: var(--accent);
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

a[href^='tel'] {
  color: inherit !important;
  cursor: inherit;
  text-decoration: none !important;
}

strong {
  font-weight: bold;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
h5,
h6 {
  font: inherit;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--accent);
  margin: 2.5rem 0 0.625rem 0;
}

h1,
.h1 {
  font-size: 1.875rem;
  line-height: 2rem;
  margin-top: 0;
  margin-bottom: 1.25rem;
}

h2,
.h2 {
  font-size: 1.25rem;
  line-height: 2.125rem;
}

h1 + h2 {
  margin-top: 0;
}

p,
ol,
ul,
dl,
dd,
dt {
  margin: 0.625rem 0 1.25rem 0;
  padding: 0;
}

dt {
  margin-bottom: 0.3125rem;
  margin-top: 0.625rem;
}

dd {
  margin-top: 0.3125rem;
  margin-bottom: 0.625rem;
}

ol,
ul,
li {
  list-style: none;
}

li,
dt,
dd {
  position: relative;
  padding: 0 0 0 2rem;
}

dd {
  padding-left: 0;
}

dt {
  color: var(--accent);
}

li {
  margin: 0;
}

ul > li:before {
  content: '-';
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  text-align: center;
}

ol,
dl {
  counter-reset: li;
}

ol > li:before,
dt:before {
  counter-increment: li;
  content: counters(li, '.') '. ';
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 2rem;
  margin: 0 0 0 -2rem;
  padding: 0 0.25rem 0 0;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.25rem;
}

.nav .swiper-pagination {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.why-us .main .swiper-pagination {
  position: absolute !important;
  left: 20px;
  bottom: 0;
  width: auto;
  height: 20px;
}

.nav .prev,
.nav .next {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  outline: none;
  width: 2.5rem;
  height: 2.3rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0;
}

.nav .prev.swiper-button-disabled,
.nav .next.swiper-button-disabled {
  cursor: default;
  pointer-events: none;
}

.nav .prev svg,
.nav .next svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
  fill: var(--accent);
  -webkit-transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.nav .prev svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.nav .next svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.nav .prev.swiper-button-disabled svg,
.nav .next.swiper-button-disabled svg {
  fill: var(--bg-borders);
}

.swiper-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: static !important;
  margin: 0 !important;
}

.swiper-pagination-bullet {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 0.375rem !important;
  height: 0.375rem !important;
  border: 1px solid var(--accent) !important;
  background: none !important;
  border-radius: 50%;
  opacity: 1 !important;
  margin: 0 0.625rem 0 0 !important;
  -webkit-transition-property: background, margin, -webkit-transform;
  transition-property: background, margin, -webkit-transform;
  transition-property: background, transform, margin;
  transition-property: background, transform, margin, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform: scale(1);
  transform: scale(1);
  position: relative;
}

.swiper-pagination-bullet-active {
  background: var(--accent) !important;
  -webkit-transform: scale(1.666);
  transform: scale(1.666);
  margin: 0 0.75rem 0 0.125rem !important;
}

.swiper-container {
  overflow: hidden;
}

.swiper-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.swiper-slide {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.break-word {
  word-break: break-all;
}

.nowrap {
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.accent {
  color: var(--accent);
}

.green {
  color: var(--green);
  margin-bottom: 1.563rem;
}

.mainwrapTerminal {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 1520px;
  width: 100%;
  min-height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}

.content {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.header,
.footer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.notification {
  position: fixed;
  top: 4.125rem;
  left: 0;
  width: 100%;
  z-index: 90;
  text-align: center;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--black);
  background: var(--accent);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  min-height: 2.875rem;
  padding: 0.25rem 1rem;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.notification a {
  color: var(--orange);
}

.notification.red {
  color: var(--accent);
  background: var(--red);
}

.notification.red a {
  font-weight: bold;
  color: var(--accent);
}

.show-notification .notification {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.headline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  background: var(--bg-main);
  border-bottom: 1px solid var(--bg-borders);
  height: 4.125rem;
  padding: 0 1.25rem;
}

.header .logo {
  cursor: pointer;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  line-height: 0;
  width: 186px;
}

.header .logo img {
  height: 1.875rem;
  width: auto;
  vertical-align: top;
}

.toggle-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.25rem;
  width: 40px;
  height: 40px;
  background: #1d192c;
  border-radius: 10px;
}

.toggle-menu > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background: var(--accent);
  width: 1.25rem;
  height: 0.125rem;
  border-radius: 0.0625rem;
  margin: 0.25rem 0;
  -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
  transform: translate3d(0, 0, 0) rotate(0deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

html.open-menu {
  overflow: hidden;
}

.open-menu .toggle-menu .pos1 {
  -webkit-transform: translate3d(0, 0.3125rem, 0) rotate(-45deg);
  transform: translate3d(0, 0.3125rem, 0) rotate(-45deg);
}

.open-menu .toggle-menu .pos2 {
  -webkit-transform: translate3d(0, -0.3125rem, 0) rotate(45deg);
  transform: translate3d(0, -0.3125rem, 0) rotate(45deg);
}

.wrap-menu,
.menu-lang-sub {
  position: fixed;
  top: 4.125rem;
  left: 0;
  width: 100%;
  height: calc(100% - 4.125rem);
  z-index: 1;
  background: var(--bg-main);
  overflow: hidden;
  overflow-y: auto;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.open-menu .wrap-menu,
.open-lang-menu .menu-lang-sub {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.menu-padding {
  padding: 1.875rem 1.25rem 3.125rem 1.25rem;
  position: relative;
}

.menu-section {
  font-size: 1.5rem;
  line-height: 1.625rem;
}

.menu-section:not(:first-child) {
  margin-top: 3.125rem;
}

.menu-section.menu-profile .btn {
  width: 100%;
  height: 4rem;
  color: var(--dark);
  border-color: var(--accent);
  background: var(--accent);
}

.menu-section.menu-profile .bottom {
  text-align: center;
}

.menu-line {
  height: 1px;
  background: var(--accent);
  margin: 3.125rem 0;
}

.menu-title,
.menu-profile-name {
  font-size: 0.875rem;
  line-height: 0.9375rem;
  margin: 0 0 1.25rem 0;
}

.menu-title {
  text-transform: uppercase;
  color: var(--main-light);
}

.menu-profile-name {
  color: var(--accent);
  text-transform: none;
  float: right;
  margin-left: 1.25rem;
  text-align: right;
}

.menu-section ul,
.menu-section li {
  margin: 0;
  padding: 0;
}

.menu-section li:not(:first-child) {
  margin-top: 0.9375rem;
}

.menu-section ul + ul {
  margin-top: 2.1875rem;
}

.menu-section li:before {
  display: none;
}

.menu-section li > * {
  color: var(--accent);
  display: inline-block;
  vertical-align: top;
  position: relative;
  text-decoration: none;
}

.menu-section ul li a {
  opacity: 0.5;
}

.menu-section ul .active a {
  opacity: 1;
}

.menu-section:nth-child(1) ul li a {
  opacity: 1;
}

.menu-section li > .toggle {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  text-transform: uppercase;
  padding: 0.5rem 1.25rem;
  margin: -0.5rem -1.25rem;
}

.menu-section li > .toggle:hover {
  text-decoration: underline;
}

.menu-section li > .toggle .arr {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  right: 0;
  margin: -0.625rem 1.25rem;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.menu-section li > .toggle .arr svg {
  width: 100%;
  height: 100%;
  fill: var(--accent);
}

.menu-section .pic {
  display: none;
  vertical-align: top;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1.125rem;
}

.menu-section .pic-left {
  margin-left: 0;
  margin-right: 0.625rem;
}

.menu-section .pic svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
  fill: var(--accent);
}

.menu-section li > label {
  padding-right: 2.75rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menu-section li .checkbox {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  right: 0;
  margin: -0.75rem 0;
  border-width: 1px;
  border-radius: 0.4375rem;
}

.menu-section.menu-contact {
  font-size: 1.125rem;
  line-height: 1.1875rem;
}

.menu-contact .menu-title {
  margin-bottom: 0.5rem;
}

.menu-contact .soc {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 1.875rem;
}

.menu-contact .soc > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
  border: 1px solid var(--main-light);
  border-radius: 50%;
}

.menu-contact .soc svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--accent);
}

.menu-lang-sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-size: 1.25rem;
  line-height: 1.3125rem;
  font-weight: normal;
}

.menu-lang-sub .back {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: var(--accent);
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin: 0.75rem 0 0 0;
  padding: 1.25rem 1.25rem 1.25rem 3.375rem;
  position: relative;
}

.menu-lang-sub .back svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--accent);
  position: absolute;
  top: 50%;
  left: 0;
  margin: -0.625rem 1.25rem;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.menu-lang-sub .wrap-scroll {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
}

.menu-lang-sub .scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.menu-lang-sub ul {
  margin: 0;
  padding: 0 1.25rem 1.25rem 1.25rem;
}

.menu-lang-sub li:before {
  display: none;
}

.menu-lang-sub li {
  margin: 0;
  cursor: pointer;
  padding: 0.6875rem 0;
  color: var(--main);
}

.menu-lang-sub li:hover {
  color: var(--accent);
}

.menu-lang-sub li > * {
  display: inline-block;
  vertical-align: top;
  color: inherit;
}

.menu-lang-sub li.active {
  color: var(--accent);
}

.head-login-links,
.head-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: 1rem;
  line-height: 1.0625rem;
  color: var(--accent);
  letter-spacing: 0.02em;
  min-width: 21.875rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  position: relative;
  margin: 0;
}

.head-user {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal;
}

.head-user .email {
  max-width: 66%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.head-user .arr {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  margin: 0 !important;
}

@media (max-width: 1593px) {
  .head-user .arr {
    margin: -0.75rem 2.05rem;
  }
}

.head-user .arr svg {
  width: 100%;
  height: 100%;
  fill: var(--accent);
}

.head-login-links:before,
.head-login-links {
  text-transform: uppercase;
  font-weight: bold;
}

.head-login-links li:before {
  display: none;
}

.head-login-links li {
  margin: 0;
  padding: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.head-login-links li:not(:first-child) {
  margin-left: 3.75rem;
}

.head-login-links,
.head-user {
  display: none;
}

.footer {
  padding: 2.5rem 1.25rem;
  color: var(--main);
  font-size: 1.125rem;
  line-height: 1.1875rem;
}

.footer .grid > * {
  margin: 0 0 3.125rem 0;
}

.footer .logo {
  display: none;
}

.footer .logo img {
  height: 1.25rem;
  width: auto;
  vertical-align: top;
}

.foot-title {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  margin: 0 0 0.9375rem 0;
  color: var(--main-light);
}

.foot-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.foot-contact .foot-title {
  margin-bottom: 0.5rem;
}

.foot-contact .soc {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1.875rem;
}

.foot-contact .soc > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
  border: 1px solid var(--main-light);
  border-radius: 50%;
}

.foot-contact .soc svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--accent);
}

.foot-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.foot-links .col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: calc(50% - 0.9375rem);
}

.foot-links ul {
  margin: 0;
  padding: 0;
}

.foot-links li:before {
  display: none;
}

.foot-links li {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

.foot-links li:not(:first-child) {
  margin-top: 0.7rem;
}

.foot-link-button {
  margin-top: 1rem;
}

.foot-link-button a {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  padding: 1rem;
  background-color: var(--accent);
  color: var(--bg-main);
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 700;
}

.foot-link-button a:hover {
  text-decoration: none;
  background-color: var(--orange);
  color: var(--accent);
}

.foot-text {
  line-height: 1.875rem;
}

.foot-copy {
  font-size: 0.875rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  color: var(--main-light);
  display: flex;
  justify-content: space-between;
}

.foot-redirect-icon {
  margin-left: 10px;
  margin-bottom: 3px;
}

.link-with-icon {
  display: flex;
  align-items: center;
}

.link-with-icon svg {
  flex-shrink: 0;
}

.content {
  padding: 6rem 1.25rem 1.875rem 1.25rem;
}

.index-content {
  overflow: hidden;
}

.page-with-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: var(--bg-main);
}

.side-menu {
  margin: 0 0 2.5rem 0;
  font-size: 0.9rem;
  line-height: 1.0625rem;
  letter-spacing: 0.02em;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.side-menu .swiper-slide {
  width: auto;
  padding: 0 0.625rem;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  position: relative;
}

.side-menu .swiper-slide.active {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  padding-right: 1.875rem;
}

.side-menu .swiper-slide.active:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0.0625rem 0.625rem;
  background: var(--accent);
  width: 1px;
}

.side-menu a,
.side-menu .link {
  color: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.side-menu a:hover,
.side-menu .link:hover {
  text-decoration: underline;
}

.side-menu .link.active {
  cursor: default;
}

.side-menu .active,
.side-menu .swiper-slide-thumb-active {
  color: var(--accent);
}

.side-menu .swiper-slide-thumb-active .link {
  text-decoration: none;
}

.side-menu-title {
  font-size: 0.875rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  color: var(--grey);
  margin: 0 0 1.5625rem 0;
  display: none;
}

.page-content {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.big-title {
  padding: 0.625rem 0 1.5625rem 0;
  border-bottom: 1px solid var(--bg-borders);
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: var(--grey);
}

.big-title h1,
.big-title .h1,
.big-title h2,
.big-title .h2 {
  margin: 0;
}

.features-list {
  padding: 2.5rem 0 3.75rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
}

.features-list ol {
  margin: 0;
  padding: 0;
  counter-reset: f;
}

.features-list li {
  padding: 0;
  margin: 0;
}

.features-list li:not(:first-child) {
  margin-top: 2.5rem;
}

.features-list li:before {
  counter-increment: f;
  content: counter(f);
  display: block;
  font-weight: normal;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--accent);
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  position: static;
  top: auto;
  left: auto;
  margin: 0 0 1.25rem 0;
  padding: 0;
  border: 1px solid var(--accent);
  text-align: center;
  line-height: 2.625rem;
}

.features-list h2,
.features-list .h2 {
  margin: 0 0 0.3125rem 0;
  font-size: 1.5rem;
  line-height: 1.625rem;
}

.features {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: var(--grey);
}

.features .el {
  border-style: solid;
  border-color: var(--bg-borders);
  border-width: 1px 0 0 0;
  padding-top: 2.5rem;
  padding-bottom: 3.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.features .el:last-child {
  padding-bottom: 0;
}

.features h2,
.features .h2 {
  font-size: 1.875rem;
  line-height: 2rem;
  margin: 0 0 1.875rem 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.features .image {
  position: relative;
  margin: 0 0 1.25rem 0;
  padding: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
}

.features .image img {
  width: 100%;
  height: auto;
}

.features .text {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.features ul {
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  text-transform: uppercase;
  color: var(--accent);
  margin: 1.875rem 0 -1.25rem 0;
}

.features li:before {
  display: none;
}

.features li {
  margin: 0;
  padding: 0.21875rem 0 1.46875rem 2.1875rem;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.features li svg {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--green);
}

.info-links {
  padding: 2.5rem 0 0.625rem 0;
  font-size: 1.125rem;
  line-height: 1.1875rem;
}

.info-links h2,
.info-links .h2 {
  font-size: 1.5rem;
  line-height: 1.625rem;
  padding: 0 0 1.875rem 0;
  margin: 0 0 3.125rem 0;
  border-bottom: 1px solid var(--accent);
}

.info-links ul {
  margin: 0;
  padding: 0;
}

.info-links li:before {
  display: none;
}

.info-links li {
  margin: 1.25rem 0 0 0;
  padding: 0;
}

.contacts-wrap {
}

.contacts-wrap .top {
  padding: 2.5rem 0 0;
}

.contacts-wrap .top .title {
  color: var(--accent);
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.625rem;
}

.contacts-wrap .top .subtitle {
  opacity: 0.7;
  padding: 0.9rem 0 0;
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
}

.contacts-wrap .top .r {
  padding: 2.5rem 0 0;
}

.contacts-item {
  font-size: 1.5rem;
  line-height: 1.625rem;
}
.contacts-wrap .bottom {
  padding: 2.5rem 0 0;
}

.soc-wrap {
  display: flex;
  flex-wrap: wrap;
}

.soc-item-wrap {
  flex-basis: 50%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.soc-item-wrap:nth-child(odd) {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.soc-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  color: var(--accent);
  height: 9.875rem;
}

.soc-item svg {
  fill: var(--accent);
}

.soc-item-text {
  display: block;
  margin: 2rem 0 0;
  font-size: 0.75rem;
}

.contacts {
  padding: 2.5rem 0 1.25rem 0;
}

.contacts .big {
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: var(--accent);
  margin: 0 0 1.25rem 0;
}

.contacts .text {
  font-size: 1.125rem;
  line-height: 1.7;
}

.contacts .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 3.75rem 0 -1.25rem -1.25rem;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.7;
  color: var(--accent);
}

.contacts .links li:before {
  display: none;
}

.contacts .links li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  padding: 0 0 1.25rem 1.25rem;
  margin: 0;
}

.contacts .links small {
  display: block;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  margin: 0 0 0.5rem 0;
  color: var(--main);
}

.contacts .soc {
  border-bottom: 1px solid var(--accent);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 3.125rem 0 0 0;
}

.contacts .soc > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  font-size: 0.75rem;
  line-height: 1.7;
  text-align: center;
  text-transform: uppercase;
  padding: 0 0 1.875rem 0;
}

.contacts .soc > *:not(:first-child) {
  border-left: 1px solid var(--accent);
}

.contacts .soc > *:before {
  content: '';
  display: block;
  padding-top: 76%;
}

.contacts .soc svg {
  position: absolute;
  left: 50%;
  top: calc(50% - 1.5625rem);
  width: 1.75rem;
  height: 1.75rem;
  margin: -0.875rem;
  fill: var(--accent);
}

.feedback-wrap {
  flex-direction: column;
}

.feedback {
  display: flex;
  justify-content: center;
  margin: 4rem 0 1rem;
}
.feedback-content {
  max-width: 62.5rem;
  width: 100%;
}
.feedback .input-label {
  margin-bottom: 1.565rem;
}
.feedback input,
.feedback textarea,
.feedback .input-placeholder {
  font-size: 1.5rem;
  line-height: 1.65rem;
}
.feedback .file-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}
.feedback .file-input .input-label {
  margin-bottom: 1rem;
}
.feedback .input-placeholder {
  padding-bottom: 0;
  border-bottom: none;
  font-size: 1.25rem;
  line-height: 1.25rem;
}
.feedback .add-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
  margin-left: 1rem;
  background: var(--bg-grey);
  transition: background 250ms ease-in-out;
  cursor: pointer;
}

.feedback .add-icon label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.feedback .add-icon > input {
  display: none;
}

.feedback .add-icon svg {
  width: 1.563rem;
  fill: var(--accent);
  transition: fill 250ms ease-in-out;
}
.feedback .add-icon:hover {
  background: var(--accent);
}
.feedback .add-icon:hover svg {
  fill: var(--bg-main);
}
.loaded-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.225rem;
  background: var(--bg-grey);
  margin-bottom: 0.125rem;
}
.loaded-item .loaded-content {
  display: flex;
  align-items: center;
  color: var(--accent);
}
.loaded-item .preview-image {
  display: flex;
  flex-shrink: 0;
  width: 3.125rem;
  height: 3.125rem;
  margin-right: 1.225rem;
}
.loaded-item .preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.loaded-item .text-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.loaded-item .file-name {
  font-size: 1.125rem;
  line-height: 1.125rem;
  margin-bottom: 1.125rem;
  word-break: break-all;
}
.loaded-item .file-info {
  font-size: 1rem;
  line-height: 1rem;
}
.loaded-item .close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  cursor: pointer;
}

.example-quote {
  background: #202027;
  padding: 1em 1.25em;
  color: var(--accent);
  border-left: 0.125rem solid var(--accent);
}

.example-quote + .example-quote {
  margin-top: 1em;
}

.example-quote-name {
  margin: 0 -1.25em 1em -1.25em;
  padding: 0 1.25em 1em 1.25em;
  border-bottom: 1px solid var(--bg-main);
  color: var(--main);
}

.example-spoilers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -1em 0 0 -1em;
}

.example-spoilers > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 1em 0 0 1em;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background: #202027;
  color: var(--accent);
  border-radius: 1em;
  padding: 1em;
  min-width: 9.75em;
}

.example-code {
  font-size: 0.8em;
  background: #202027;
  padding: 1.25em 1.5625em;
  color: var(--accent);
  border-left: 0.125rem solid var(--accent);
}

.example-code + .example-code {
  margin-top: 1.25em;
}

.example-code-name {
  font-size: 1.25em;
  margin: 0 -1.25em 1em -1.25em;
  padding: 0 1.25em 1em 1.25em;
  border-bottom: 1px solid var(--bg-main);
  color: var(--main);
}

.info-table {
  margin: 2.5rem -1.25rem;
  padding: 0 1.25rem;
  overflow: auto;
}

.info-table:last-child {
  margin-bottom: 1.25rem;
}

.info-table table {
  min-width: 100%;
  border-collapse: collapse;
  border: 0;
}

.info-table td,
.info-table th {
  text-align: left;
  vertical-align: top;
  border: 0;
  padding: 0 0.625rem;
  width: 50%;
}

.info-table td:first-child,
.info-table th:first-child {
  padding-left: 0;
}

.info-table td:last-child,
.info-table th:last-child {
  padding-right: 0;
}

.info-table th {
  font-weight: 500;
}

.info-table td {
  padding-top: 0.625rem;
  color: var(--accent);
}

.info-table ul,
.info-table ol {
  margin: 0;
}

.top-index {
  position: relative;
  min-height: 100vh;
  margin: -6rem -1.25rem 0 -1.25rem;
  padding-top: 4.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  overflow: hidden;
}

.top-index .top {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  padding: 9.125rem 1.25rem 4.8125rem 1.25rem;
}

.top-index video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.top-index .cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--bg-main);
  opacity: 0.7;
}

.top-index .play-btn {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 4.625rem;
  height: 4.625rem;
  border-radius: 50%;
  margin: 2.25rem 1.875rem;
}

.top-index .play-btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.top-index .play-btn:hover:before {
  -webkit-backdrop-filter: blur(1.25rem);
  backdrop-filter: blur(1.25rem);
}

.top-index .play-btn svg:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 144%;
  height: 144%;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
  transform: translate3d(-50%, -50%, 0) rotate(0deg);
  -webkit-animation: play-rotate 10s linear infinite;
  animation: play-rotate 10s linear infinite;
}

.top-index .play-btn:hover svg:nth-child(1),
.top-index.scrolled .play-btn svg:nth-child(1) {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@-webkit-keyframes play-rotate {
  from {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  to {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(360deg);
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

@keyframes play-rotate {
  from {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  to {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(360deg);
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.top-index .play-btn svg:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin: -0.75rem;
  fill: var(--accent);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.top-index .play-btn:hover svg:nth-child(1) {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.top-index .play-btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.top-index .text-wrap {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: var(--accent);
  position: relative;
}

.top-index .text-wrap .text strong {
  font-weight: inherit;
  color: var(--accent);
}

.top-index h1,
.top-index .h1 {
  font-weight: normal;
  font-size: 2.25rem;
  line-height: 2.4375rem;
  margin: 0 0 0.625rem 0;
}

.top-index .inputs {
  margin-top: 1.875rem;
}

.top-index .input input {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.1875rem;
  padding-bottom: 1.5625rem;
  letter-spacing: normal;
}

.top-index .input input::-webkit-input-placeholder {
  color: var(--accent);
}

.top-index .input input::-moz-placeholder {
  color: var(--accent);
}

.top-index .input input:-ms-input-placeholder {
  color: var(--accent);
}

.top-index .inputs .btn {
  width: 100%;
  margin-top: 1.875rem;
}

.top-index .bottom {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  display: none;
  visibility: hidden;
}

.top-index .bottom .arrow {
  width: 1.875rem;
  height: 1.875rem;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 2.625rem 3.125rem;
  z-index: 3;
}

.top-index .bottom .arrow svg {
  width: 100%;
  height: 100%;
  fill: var(--accent);
}

.top-index .bottom .soc {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2rem 2.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 3;
}

.top-index .bottom .soc > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 3.125rem;
  height: 3.125rem;
  margin-left: 0.25rem;
  border-radius: 50%;
  position: relative;
}

.top-index .bottom .soc svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: var(--accent);
  border-radius: 50%;
}

.top-index .connects {
  padding: 1.625rem 0 2.25rem 0;
  margin: 0 18.75rem;
  overflow: hidden;
}

.top-index .connects-title {
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: var(--accent);
  margin: 0 0 1.875rem 0;
}

.top-index .connects .swiper-container:before,
.top-index .connects .swiper-container:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 35%;
  pointer-events: none;
  z-index: 2;
}

.top-index .connects .swiper-container:before {
  left: 0;
  background: -webkit-linear-gradient(left, rgba(13, 13, 17, 1) 0%, rgba(13, 13, 17, 0) 100%);
  background: linear-gradient(to right, rgba(13, 13, 17, 1) 0%, rgba(13, 13, 17, 0) 100%);
}

.top-index .connects .swiper-container:after {
  right: 0;
  background: -webkit-linear-gradient(left, rgba(13, 13, 17, 0) 0%, rgba(13, 13, 17, 1) 100%);
  background: linear-gradient(to right, rgba(13, 13, 17, 0) 0%, rgba(13, 13, 17, 1) 100%);
}

.top-index .connects .swiper-wrapper {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.top-index .connects .swiper-slide {
  width: auto;
  padding: 0 1.875rem;
}

.top-index .connects .swiper-slide img {
  height: 2.0625rem !important;
  width: auto;
  vertical-align: top;
  opacity: 0.7;
  filter: grayscale(1);
}

.page-login {
  margin: 1.875rem 0 2.5rem 0;
}

.login-title {
  margin: 0 0 3.125rem 0;
}

.login-title h1,
.login-title .h1 {
  margin: 0;
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 2rem;
}

.login-title .h1 {
  color: var(--main);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.login-title .h1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.login-title .h1 .slash {
  margin: 0 0.5em;
}

.login-title .h1 a {
  color: inherit;
}

.login-title .h1 h1 {
  display: inline !important;
  margin: 0 !important;
}

.login-title .text {
  font-size: 1.125rem;
  line-height: 1.1875rem;
  margin: 0.9375rem 0 0 0;
  color: var(--accent);
}

.licenses-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.licenses-header h1 {
  font-size: 36px;
  margin-bottom: 0;
}

.licenses-toggle {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  margin-top: -1rem;
}

.licenses-toggle .arr {
  display: flex;
  align-items: center;
}

.licenses-toggle .arr svg {
  fill: #ff721c;
  width: 1.5rem;
  height: 1.5rem;
}

.licenses-toggle .toggle-rotated svg {
  transform: rotate(180deg);
}

.licenses-toggle .filters {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 0;
  padding: 0.75rem 1.75rem;
  display: flex;
  flex-direction: column;
  background: #19191d;
  box-sizing: border-box;
  cursor: pointer;
}

.licenses-toggle .filters .filter-active {
  color: #ff721c;
}

.licenses-filter {
  color: #ff721c;
}

.licenses-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.licenses-grid__item {
  padding: 35px 30px 40px;
  border-radius: 20px;
  background-color: var(--grey-2);
}

@media (max-width: 1240px) {
  .licenses-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .licenses-grid {
    display: block;
  }

  .licenses-grid__item:not(:first-child) {
    margin-top: 20px;
  }
}

.licenses-grid__item__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.licenses-grid__item__top .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: var(--bg-grey);
}

.licenses-grid__item__top .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 150px;
  border-radius: 12px;
  background-color: var(--accent);
  font-size: 14px;
  line-height: 1;
  color: #0d0d11;
  text-transform: none;
}

.licenses-grid__item__top .btn:hover {
  background-color: var(--grey);
}

.licenses-grid__item__heading {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: var(--accent);
}

.licenses-grid__item__creds {
  margin-top: 30px;
}

.licenses-grid__item__cred {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 12px;
  background-color: var(--grey-3);
  font-size: 12px;
}

.licenses-grid__item__cred:not(:first-child) {
  margin-top: 10px;
}

.licenses-grid__item__cred .txt {
  font: bold 16px/1 var(--font-family);
  color: var(--accent);
}

.licenses-grid__item__cred .copy {
  width: 40px;
  height: 40px;
  fill: var(--accent);
}

.licenses-grid__item__cred .copy:hover {
  fill: var(--grey);
}

.pay-with-stripe {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.pay-with-stripe-label {
  font-size: 1rem;
}

.pay-with-stripe-cards {
  margin-top: 0.5rem;
}

.pay-with-stripe-cards svg {
  margin-right: 0.5rem;
}

.my-ava .img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: block;
  width: 5.625rem;
  height: 5.625rem;
  background: var(--bg-borders);
  position: relative;
  overflow: hidden;
}

.my-ava .img input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.my-ava .img img {
  width: 100%;
  height: 100%;
}

.my-ava .form-title {
  display: none;
}

.my-ava .text {
  margin: 1.25rem 0 0 0;
}

.my-ava .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.my-ava .links .btn {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 0.75em;
  line-height: 1rem;
  height: 3.125rem;
}

.my-ava .links > .btn:not(:first-child) {
  margin-left: 1.25rem;
}

.links-2fa {
  margin: 3.125rem 0 0 0;
}

.links-2fa .col {
  padding: 2.5rem 0 0 0;
  margin: 2.5rem 0 0 0;
  border-top: 1px solid var(--accent);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.links-2fa h2,
.links-2fa .h2 {
  margin-top: 0;
}

.links-2fa .text {
  margin: 0 0 2.5rem 0;
}

.links-2fa .btn {
  height: 3.125rem;
}

.qr-code img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 16.25rem;
}

.why-us {
  padding: 2.5rem 0 3.125rem 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.why-us .head {
  border-bottom: 1px solid var(--accent);
  padding: 0 0 3.125rem 0;
  margin: 0 0 3.125rem 0;
}

.why-us .head .btn {
  width: 100%;
}

.why-us h2,
.why-us .h2 {
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 2rem;
  margin: 0 0 1.875rem 0;
}

.why-us h3,
.why-us .h3 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.3125rem;
  margin: 0 0 0.9375rem 0;
}

.why-us .head .btn:hover {
  background: var(--orange);
  border-color: var(--orange);
  color: var(--accent);
}

.why-us .thumbs {
  margin: 0 -1.25rem;
  display: none;
}

.why-us .thumbs .swiper-container {
  padding: 0 1.25rem;
}

.why-us .thumbs .swiper-slide {
  width: 25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;
  color: var(--accent);
  padding: 2.2rem 1.5rem 2.2rem 3.125rem;
  cursor: pointer;
  position: relative;
  border-left: 1px solid var(--bg-borders);
  -webkit-transition-property: color, border-color, background;
  transition-property: color, border-color, background;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  height: auto !important;
}

.why-us .thumbs .swiper-slide.swiper-slide-thumb-active {
  background-color: var(--accent);
  border-color: transparent;
  color: var(--bg-main);
  cursor: default;
}

.why-us .thumbs .swiper-slide:first-child {
  border-left-color: transparent;
}

.why-us .thumbs .swiper-slide .line {
  position: absolute;
  bottom: 0;
  left: -1px;
  right: 0;
  height: 0.25rem;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  background: var(--orange);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.why-us .thumbs .swiper-slide.swiper-slide-thumb-active .line {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 7s;
  transition-duration: 7s;
}

.why-us .main {
  margin: 0 -1.25rem;
}

.why-us .main .swiper-slide {
  width: 100%;
  padding: 0 1.25rem;
  height: auto !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: var(--bg-main);
}

.why-us .main .text {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.why-us .main .image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  margin: 1.875rem 0 0 0;
}

.why-us .main .image img {
  width: 100%;
  height: auto;
}

.why-us .nav {
  margin: 2.5rem 0 0 0;
  padding-right: 20px;
  justify-content: flex-end;
}

.notification-btn {
  position: absolute;
  right: 10%;
  background: 0;
  width: 40px;
  height: 20px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.no-licenses {
  font-size: 1.25rem;
}

.notification-btn > svg {
  transform: scale(0.6);
}

.multi-coverage {
  padding: 2.5rem 0 0 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: var(--grey);
}

.multi-coverage h2,
.multi-coverage .h2 {
  font-size: 1.875rem;
  line-height: 2rem;
  margin: 0 0 0.625rem 0;
  font-weight: normal;
}

.multi-coverage .head {
  position: relative;
  z-index: 1;
}

.multi-coverage .bg {
  position: relative;
  width: 2.801875rem;
  height: 2.554375rem;
  margin: 14.275rem auto 13.9375rem auto;
  padding: 0;
}

.multi-coverage .bg:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -53.68%, 0);
  transform: translate3d(-50%, -53.68%, 0);
  width: 2500%;
  height: 2500%;
  background: url(/img/multi-bg.svg) no-repeat 50% 50%;
  background-size: contain;
  pointer-events: none;
}

.multi-coverage .bg li {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 0.625rem;
  line-height: 0.6875rem;
  color: var(--accent);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 5.125rem;
  height: 5.125rem;
  border-radius: 50%;
  background: var(--bg-main);
  border: 1px solid var(--bg-borders);
  margin: 0;
  padding: 0 1rem;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 0 0 0 0.5rem var(--bg-main);
}

.multi-coverage .bg li:before {
  display: none;
}

.multi-coverage .bg li:nth-child(1) {
  top: -330%;
}

.multi-coverage .bg li:nth-child(2) {
  top: -55%;
  left: 360%;
}

.multi-coverage .bg li:nth-child(3) {
  top: 345%;
  left: 243%;
}

.multi-coverage .bg li:nth-child(4) {
  top: 345%;
  left: -143%;
}

.multi-coverage .bg li:nth-child(5) {
  top: -55%;
  left: -260%;
}

.connections {
  padding: 0 0 3.125rem 0;
}

.connections h2,
.connections .h2 {
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 2rem;
  margin: 0 0 3.125rem 0;
}

.connections h2 sup,
.connections .h2 sup {
  vertical-align: top;
  font-size: 1rem;
  line-height: 1.0625rem;
  letter-spacing: 0.02em;
  color: var(--orange);
}

.connections ul {
  margin: 0 -1.25rem;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.connections li:before {
  display: none;
}

.connections li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
  margin: 0;
  padding: 1.5rem;
  height: 8.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-color: var(--bg-borders);
  border-width: 0 1px 1px 0;
}

.connections li:nth-child(2n) {
  border-right-width: 0;
}

.connections li:nth-child(1),
.connections li:nth-child(6),
.connections li:nth-child(11) {
  border-left-width: 1px;
}

.connections li:nth-child(1),
.connections li:nth-child(2) {
  border-top-width: 1px;
}

.connections li img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 2.8125rem;
}

.download-now {
  padding: 5rem 1.25rem 3.125rem 1.25rem;
  margin: 0 -1.25rem;
}

.download-now h2,
.download-now .h2 {
  font-size: 2.25rem;
  line-height: 2.4375rem;
  margin: 0 0 1.875rem 0;
}

.download-now .input input {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.1875rem;
  padding-bottom: 1.5625rem;
  letter-spacing: normal;
}

.download-now .input input::-webkit-input-placeholder {
  color: var(--accent);
}

.download-now .input input::-moz-placeholder {
  color: var(--accent);
}

.download-now .input input:-ms-input-placeholder {
  color: var(--accent);
}

.download-now .inputs .btn {
  width: 100%;
  margin-top: 1.875rem;
}

.download-page {
  padding: 0 0 0.625rem 0;
}

.download-page .l {
  padding: 0 0 3.75rem 0;
  border-bottom: 0.125rem solid var(--accent);
}

.download-page .r {
  padding-top: 3.75rem;
}

.download-page .big-title {
  margin: 0 0 0.9375rem 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.download-page .head {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: var(--accent);
  margin: 0 0 2.5rem 0;
}

.download-page .submit {
  margin: 1.875rem 0 0 0;
}

.download-page .submit .btn {
  width: 100%;
}

.download-page .submit .submit-info {
  margin-top: 0.9375rem;
}

.download-page .submit-r {
  font-size: 0.875rem;
  line-height: 0.9375rem;
  color: var(--grey);
  text-align: center;
  margin: 1.875rem 0 0 0;
}

.download-page .submit-r strong {
  color: var(--accent);
  font-weight: normal;
}

.download-page .submit-r p {
  margin: 0.625rem 0;
}

.download-page .submit-r p:first-child {
  margin-top: 0 !important;
}

.download-page .submit-r p:last-child {
  margin-bottom: 0 !important;
}

.download-page .info {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: var(--accent);
}

.download-page .info .col {
  margin: 0 0 1.875rem 0;
}

.download-page .info .col.wide {
  margin-bottom: 2.5rem;
}

.download-page .info .col:last-child {
  margin-bottom: 0;
}

.download-page .info-title {
  font-size: 0.75rem;
  line-height: 0.8125rem;
  color: var(--main);
  letter-spacing: normal;
  text-transform: uppercase;
  margin: 0 0 0.9375rem 0;
}

.download-page .info p {
  margin: 0.9375rem 0 0 0;
}

.download-page .info .grey {
  color: var(--main);
}

.download-page .info sup {
  color: var(--orange);
  vertical-align: top;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  text-transform: uppercase;
  letter-spacing: normal;
}

.setup-head {
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: var(--accent);
}

.setup-head .big-title {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 2.1875rem;
}

.setup-head .nav {
  margin: 0 1rem 1.875rem 0;
  justify-content: flex-end;
}

.setup-head .nav .swiper-pagination {
  counter-reset: stp;
}

.setup-head .nav .swiper-pagination .swiper-pagination-bullet:before {
  content: counter(stp);
  counter-increment: stp;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0) scale(0.2);
  transform: translate3d(-50%, -50%, 0) scale(0.2);
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1;
  color: var(--bg-main);
  opacity: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.setup-head .nav .swiper-pagination .swiper-pagination-bullet-active {
  -webkit-transform: scale(5);
  transform: scale(5);
  margin: 0 1.375rem 0 0.75rem !important;
}

.setup-head .nav .swiper-pagination .swiper-pagination-bullet-active:before {
  opacity: 1;
}

.setup-head .steps {
  margin: 0 -1.25rem;
}

.setup-head .steps .swiper-wrapper {
  counter-reset: st;
}

.setup-head .swiper-slide {
  width: 100%;
  padding: 0 1.25rem;
  background: var(--bg-main);
}

.setup-head .steps .num {
  width: 100%;
  height: 2.75rem;
  position: relative;
  font-size: 1.125rem;
  line-height: 2.75rem;
  text-align: left;
  padding: 0 0 0 4rem;
  margin: 0 0 1.875rem 0;
  display: none;
}

.setup-head .steps .num:before {
  content: counter(st);
  counter-increment: st;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2.75rem;
  height: 2.75rem;
  line-height: 2.625rem;
  text-align: center;
  font-size: 1rem;
  border: 1px solid var(--accent);
  border-radius: 50%;
}

.setup-head .steps .image {
  margin: 0 0 1.875rem 0;
  position: relative;
  display: block;
  pointer-events: all;
  cursor: pointer;
}

.setup-head .steps .image img {
  width: 100%;
  height: auto;
}

.setup-head .steps .text {
  pointer-events: all;
}

.setup-head .steps .swiper-pagination {
  position: absolute !important;
  bottom: 8rem;
  left: 1.25rem;
  width: 7rem;
}

.setup-config {
  padding: 2.5rem 0 0 0;
}

.setup-config .big-title {
  margin-bottom: 1.875rem;
  padding: 0;
  border-bottom: 0;
}

.setup-config .big-title h2,
.setup-config .big-title .h2 {
  font-size: 1.875rem;
  line-height: 2rem;
}

.setup-config .big-title .text {
  margin-top: 0.625rem;
}

.setup-config .setup-group {
  position: relative;
  margin: 0 0 1.875rem 0;
  border-bottom: 0.125rem solid var(--accent);
}

.setup-config .setup-group .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0.3125rem 0;
}

.setup-config .setup-group .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.3125rem 0.625rem 0.3125rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  font-size: 1.25rem;
  line-height: 2.125rem;
  color: var(--main);
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  background: rgba(255, 255, 255, 0.05);
}

.setup-config .setup-group .link.active {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  color: var(--accent);
}

.setup-config .setup-group:not(.open) .link:not(.active) {
  display: none;
}

.setup-config .setup-group .link svg {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  margin: -0.625rem 0;
  fill: var(--accent);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition-property: fill, -webkit-transform;
  transition-property: fill, -webkit-transform;
  transition-property: fill, transform;
  transition-property: fill, transform, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.setup-config .setup-group.open .link svg {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.setup-config .setup-group .link:not(.active) svg {
  opacity: 0;
}

.setup-config .side-menu {
  font-size: 1.25rem;
  line-height: 2.125rem;
  margin-bottom: 2.75rem;
}

.setup-config .side-menu.js_tabs .swiper-wrapper {
  overflow-x: scroll;
  padding-bottom: 1rem;
}

.text-page {
  padding: 0;
}

.text-page > .side-menu {
  font-size: 0.9rem;
  margin-bottom: 0;
}

.text-page li:before {
  top: 1.7rem;
}
.privacy-page .text-page li:before {
  top: 0;
}
.text-page li > h2 {
  margin-top: 0;
}

.zoom-pic {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  background: var(--accent);
  margin: 0.625rem;
}

.zoom-pic svg {
  width: 100%;
  height: 100%;
  fill: var(--bg-main);
  vertical-align: top;
}

.folded-tab:not(.visible) {
  display: none;
}
@media screen and (max-width: 1019px) {
  .content .buyers-information-container .buyers-information-title {
    padding-bottom: 1.875rem;
    margin-bottom: 3.125rem;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    border-bottom: 0.063rem solid #fff;
    font-size: 1.5rem;
    line-height: 1.625rem;
  }
  .content .buyers-information-container .payment-for-license {
    flex-direction: column;
    display: flex;
  }
  .content .buyers-information-container .payment-for-license span {
    margin-bottom: 0.5rem;
  }
}

.config-section-head {
  display: none !important;
  border-bottom: 0.125rem solid var(--accent);
  margin: 0 0 3.75rem 0;
  padding: 0 0 2.5rem 0;
  background: var(--bg-main);
}

.config-section-head .image {
  display: block;
  position: relative;
  margin: 0 0 1.875rem 0;
}

.config-section-head .image img {
  width: 100%;
  height: auto;
}

.config-section-head h3,
.config-section-head .h3 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 0 1.5625rem 0;
}

.config-section-head .links .btn {
  margin: 1.25rem 0 0 0;
  width: 100%;
}

.config-section-head .links .btn-secondary {
  border-color: transparent;
  text-transform: none;
}

.config-descr {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: var(--accent);
  background: var(--bg-main);
  overflow: hidden;
}

.config-descr h3,
.config-descr .h3 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 1.25rem;
  margin-top: 0;
}

.config-descr .image {
  display: block;
  border: 0;
  outline: none;
  margin: 1.25rem 0 2.5rem 0;
  position: relative;
  pointer-events: none;
}

.config-descr .image img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.prices {
  padding-bottom: 2.5rem;
}

.prices .big-title {
  margin-bottom: 2.55rem;
  border-bottom-color: transparent;
  padding-bottom: 0;
}

.prices .big-title .text {
  margin-top: 1.563rem;
}
.prices .big-title .title-wrap h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.prices .big-title .title-wrap .detail-link {
  font-size: 0.875rem;
  line-height: 2.125rem;
  color: #ff721c;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.prices .title-wrap .detail-link svg {
  width: 0.75rem;
  height: 0.5rem;
  transform: rotate(-90deg);
  margin: 0 0 0.2rem 0.3rem;
}

.prices .big-title ul {
  text-align: left;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  letter-spacing: normal;
  color: var(--accent);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 -0.9375rem -1.875rem;
  padding: 0;
}

.prices .big-title li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  padding: 0.28125rem 0 0.28125rem 2.25rem;
  margin: 0 0 0.9375rem 1.875rem;
}

.prices .big-title li:before {
  display: none;
}

.prices .big-title li svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--green);
}

.prices .grid .r {
  border-color: var(--bg-borders);
}

.prices .grid .l-info,
.prices .grid .r-info {
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin-top: 1.875rem;
}
.prices .grid .r-info {
  border-bottom: 0.063rem solid #fff;
  /*margin-top: 0;*/
  margin-bottom: 3.75rem;
  padding-bottom: 3.75rem;
}
.prices .grid .r-info:last-child {
  margin-top: 1.875rem;
  margin-bottom: 3.75rem;
  padding-bottom: 3.75rem;
}
.prices .grid-title {
  padding-bottom: 1.563rem;
}
.prices .grid-title span {
  font-size: 1rem;
  line-height: 1.063rem;
  color: #fff;
}

.prices .thumbs {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  display: none;
}

.prices .thumbs .swiper-slide {
  width: 25%;
}

.prices .main .nav {
  justify-content: flex-end;
  margin-top: 30px;
}

.prices .main .swiper-slide {
  height: auto !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: var(--bg-main);
}
.prices .main .swiper-slide-active {
  /*transform: translate3d(0px, 0px, 0px)!important;*/
}
.prices .main .swiper-slide-next {
  transform: translate3d(-13%, 0px, 0px) !important;
}

.plan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-top: 1rem;
}
.plan .discount-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.563rem;
  font-size: 1rem;
  line-height: 1.063rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
}

.plan .discount-wrap strong {
  margin-top: 0.75rem;
  font-size: 0.75rem;
  line-height: 0.813rem;
  text-transform: uppercase;
  color: #0de7b2;
}

.plan .text {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  width: 100%;
}

.plan .btn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  margin-top: 2.5rem;
}
.plan .time {
  display: none;
}
.plan .time,
.prices .thumbs .swiper-slide,
.checkout-plans .list .bg {
  font-size: 1rem;
  line-height: 1.0625rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--accent);
  height: 4.625rem;
}

.plan .time strong,
.prices .thumbs .swiper-slide strong,
.checkout-plans .list .bg strong {
  display: block;
  color: var(--green);
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  letter-spacing: normal;
  margin: 0.75rem 0 0 0;
}

.plan .price {
  /*height: 5rem;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  color: var(--accent);
  position: relative;
}

.plan .price sup {
  vertical-align: top;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.1875rem;
  margin: 0 0.5625rem 0 0;
}

.plan .price del {
  position: absolute;
  top: 0;
  left: 75%;
  width: 3.625rem;
  height: 1.875rem;
  line-height: 1.875rem;
  text-align: center;
  text-decoration: line-through;
  color: var(--bg-main);
  background: var(--orange);
  font-weight: normal;
  font-size: 1.125rem;
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
  margin: -0.9375rem 1rem;
}

.plan .price del:before,
.plan .price del:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 1.375rem;
  height: 1.375rem;
  background: var(--orange);
  border-radius: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.plan .price del:before {
  left: -0.3125rem;
}

.plan .price del:after {
  right: -0.3125rem;
}

.plan .price del > * {
  display: block;
  position: relative;
  z-index: 1;
}

.plan .price del > *:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0.25rem;
  height: 0.25rem;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  background: var(--bg-main);
  border-radius: 50%;
}

.plan .price .big {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 2.9375rem;
}

.plan .price .green,
.plan .price .small {
  color: var(--green);
}

.plan .price .small {
  margin: 0.3125rem 0 2rem 0;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
}

.plan ul {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  color: var(--accent);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0 0 -0.9375rem -1.875rem;
  padding: 0;
  width: 84%;
}

.plan-player .text ul {
  width: 100%;
}

.plan li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  padding: 0.28125rem 0 0.28125rem 2.25rem;
  margin: 0 0 0.9375rem 1.875rem;
}

.plan li:before {
  display: none;
}

.plan li svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--green);
}

.grid-player {
  margin-top: 4rem;
  padding-bottom: 3rem;
}

.prices .grid-player .l-info {
  display: none;
}

.prices .grid-player .r {
  margin-top: 0;
  /*padding-top: 2rem;*/
  border-top: none;
}
.r-player-info {
  display: inline-block;
  flex-direction: column;
  margin-top: 2.5rem;
}
.prices .grid-player .r .time {
  height: 100%;
  font-size: 1rem;
}
.detailed {
  display: none;
}
.detailed-mobile .detailed-wrap {
  width: calc(100% + 1.876rem);
  position: relative;
  left: -0.938rem;
}
.detailed-mobile .table-item {
  padding: 1.25rem 0.938rem;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 1.25rem;
}
.detailed-mobile .text-wrap {
  display: flex;
  flex-direction: column;
}
.detailed-mobile .table-item:not(:first-child) {
  margin-top: 0.938rem;
}
.detailed-mobile .title {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.563rem;
  color: #fff;
  margin-bottom: 0.313rem;
}
.detailed-mobile .subTitle {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: rgba(255, 255, 255, 0.5);
  height: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  transition: 200ms linear;
}
.detailed-mobile .subTitleFull {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: rgba(255, 255, 255, 0.5);
  height: max-content;
  overflow: initial;
  word-break: break-word;
  display: block;
}
.detailed-mobile .subTitleFull svg {
  width: 1rem;
  height: 1rem;
  margin-left: 0.2rem;
  margin-top: 0.1rem;
}
.detailed-mobile .show-more {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ff721c;
  font-size: 0.875rem;
  line-height: 0.938rem;
  margin-top: 0.969rem;
}
.detailed-mobile .show-more svg {
  width: 0.6rem;
  height: 0.4rem;
  margin-left: 0.325rem;
}
.detailed-mobile .show-less {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ff721c;
  font-size: 0.875rem;
  line-height: 0.938rem;
  margin-top: 0.969rem;
}
.detailed-mobile .show-less svg {
  width: 0.6rem;
  height: 0.4rem;
  margin-left: 0.325rem;
  transform: rotateX(180deg);
}
.detailed-mobile .icons-wrap {
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  margin-top: 1.25rem;
  justify-content: space-around;
}
.detailed-mobile .icon-wrap {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detailed-mobile .icon-wrap:not(:first-child) {
  border-left: 0.063rem solid rgba(255, 255, 255, 0.05);
}
.detailed-mobile .icon-text {
  font-size: 0.75rem;
  padding-top: 0.906rem;
  text-transform: initial;
  text-align: center;
}
.detailed-mobile .icon-wrap:nth-of-type(1) .icon-text {
  text-align: center;
}
.detailed-mobile .icon-wrap svg {
  width: 1.5rem;
  height: 1.5rem;
}
.detailed-title {
  font-size: 1.875rem;
  line-height: 2rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 2.5rem;
}

.grid-player .plan .big {
  font-size: 2.75rem;
  line-height: 2.938rem;
}
.grid-player .plan .period {
  font-size: 1rem;
  line-height: 1.063rem;
  text-transform: uppercase;
  margin-top: 0.313rem;
}

.grid-player .plan-player .time {
  font-size: 1.5rem;
}

.grid-player .plan .price {
  height: 100%;
  margin-bottom: 1.5rem;
}
.grid-player .plan {
  padding-top: 0;
}

.grid-player .plan-player .price {
  height: 100%;
  margin-bottom: 2rem;
}
.price .player-info {
  display: none;
}
.grid-player .thumbs-player {
  display: block;
}
.grid-player .player-title {
  font-size: 1rem;
  line-height: 1.063rem;
  text-transform: uppercase;
}

.big-text {
  padding: 0 0 2.5rem 0;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2rem;
  color: var(--accent);
}
.big-text-download {
  width: 100%;
  color: #ff721c;
  cursor: pointer;
}

.big-text a {
  color: var(--orange);
}

.checkout {
  padding: 2.5rem 0;
}

.checkout .r {
  margin-top: 3.125rem;
}

.checkout-title {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.1875rem;
  margin: 0 0 1.875rem 0;
  color: var(--accent);
}

.checkout-title h2,
.checkout-title .h2 {
  margin: 0 0 0.625rem 0;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.5;
}

.checkout-plans .list {
  display: none;
}

.checkout-plans .list > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.checkout-plans .list .bg {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-style: solid;
  border-color: var(--bg-borders);
  border-width: 0 1px 0 0;
  -webkit-transition-property: color, background;
  transition-property: color, background;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.checkout-plans .list > *:first-child .bg {
  border-left-width: 1px;
}

.checkout-plans .list .bg strong {
  -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.checkout-plans input:nth-child(1):checked ~ .list > *:nth-child(1) .bg,
.checkout-plans input:nth-child(2):checked ~ .list > *:nth-child(2) .bg,
.checkout-plans input:nth-child(3):checked ~ .list > *:nth-child(3) .bg,
.checkout-plans input:nth-child(4):checked ~ .list > *:nth-child(4) .bg {
  cursor: default;
  color: var(--bg-main);
  background: var(--accent);
}

.checkout-plans input:nth-child(1):checked ~ .list > *:nth-child(1) .bg strong,
.checkout-plans input:nth-child(2):checked ~ .list > *:nth-child(2) .bg strong,
.checkout-plans input:nth-child(3):checked ~ .list > *:nth-child(3) .bg strong,
.checkout-plans input:nth-child(4):checked ~ .list > *:nth-child(4) .bg strong {
  color: var(--green-dark);
  font-weight: bold;
}

.checkout-plans .plan {
  display: none;
}

.checkout-plans input:nth-child(1):checked ~ .plans .plan:nth-child(1),
.checkout-plans input:nth-child(2):checked ~ .plans .plan:nth-child(2),
.checkout-plans input:nth-child(3):checked ~ .plans .plan:nth-child(3),
.checkout-plans input:nth-child(4):checked ~ .plans .plan:nth-child(4) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkout-plans .plan .time {
  position: absolute;
  top: 1.875rem;
  left: 9.25rem;
}

.checkout-plans .plan .time strong {
  font-size: 0.875rem;
  line-height: 0.9375rem;
  margin-top: 0.3125rem;
}

.checkout .code {
  margin: 0 0 2.5rem 0;
}

.checkout .code .inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.checkout .code .input {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.checkout .code .input input {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.3125rem;
  padding-bottom: 1.25rem;
}

.checkout .code .btn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-width: 6.875rem;
  margin: 0 0 0 1.25rem;
}

.checkout .code .input-info {
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 0.6875rem;
  text-transform: uppercase;
  margin-top: 1.25rem;
  color: var(--accent);
}

.checkout .total {
  margin: 0 0 2.5rem 0;
  padding: 0;
  color: var(--accent);
  font-size: 1rem;
  line-height: 1.0625rem;
  letter-spacing: 0.02em;
}

.checkout .total li:before {
  display: none;
}

.checkout .total li {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.checkout .total li:not(:first-child) {
  margin-top: 1.25rem;
}

.checkout .total .green > *:last-child {
  font-weight: bold;
}

.checkout .total li.big {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.625rem;
  margin-top: 1.875rem;
}

.checkout .submit .btn {
  width: 100%;
}

.checkout .submit-info {
  margin-top: 1.25rem;
}

.checkout-done {
  padding: 2.5rem 0;
  color: var(--accent);
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
}

.checkout-done p {
  margin-top: 0;
}

.checkout-done .r {
  margin-top: 4.6875rem;
}

.checkout-done h2,
.checkout-done .h2 {
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 2.5rem 0 1.25rem 0;
}

.checkout-done h2:first-child,
.checkout-done .h2:first-child {
  margin-top: 0 !important;
}

.checkout-done .info {
  margin: 0 0 2.5rem 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.1875rem;
  letter-spacing: normal;
}

.checkout-done .info li {
  position: relative;
  margin: 0 0 1.875rem 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkout-done .info li:before {
  display: none;
}

.checkout-done .info .txt {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.checkout-done .info strong {
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  color: var(--main);
  display: block;
  margin: 0 0 0.625rem 0;
}

.checkout-done .info .copy {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
  background: var(--bg-grey);
}

.checkout-done .info .copy svg {
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5625rem;
  fill: var(--accent);
}

.checkout-done .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkout-done .links > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.checkout-done .links > *:not(:first-child) {
  margin-left: 0.625rem;
}

.checkout-done .links .btn {
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 0.8125rem;
}

.checkout-done .r ul {
  font-size: 0.875rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  letter-spacing: normal;
  margin: 1.5625rem 0 0 0;
}

.checkout-done .r li:before {
  display: none;
}

.checkout-done .r li {
  position: relative;
  padding: 0.28125rem 0 0.28125rem 2.75rem;
  margin: 0.9375rem 0 0 0;
}

.checkout-done .r li svg {
  fill: var(--green);
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
}

.personal-head-email {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.625rem;
  margin: 0 0 3.125rem 0;
  color: var(--accent);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.license-card {
  position: relative;
  margin-bottom: 1.25rem;
}

.license-card.with-btn {
  padding-bottom: 6.875rem;
}

.license-card:not(:first-child) {
  margin-top: 4.375rem;
}

.license-card .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  border-bottom: 1px solid var(--bg-borders);
  padding: 0 0 1.875rem 0;
  margin: 0 0 1.875rem 0;
  position: static;
}

.license-card .head h2,
.license-card .head .h2 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 1.5rem;
  line-height: 1.625rem;
  margin: 0 1.25rem 0 0;
}

.license-card .head .btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.license-card .info {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.1875rem;
  color: var(--accent);
}

.license-card .info li:before {
  display: none;
}

.license-card .info li {
  position: relative;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.license-card .info li:not(:first-child) {
  margin-top: 1.875rem;
}

.license-card .info .txt {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.license-card .info strong {
  display: block;
  color: var(--main);
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  margin: 0 0 0.625rem 0;
}

.license-card .info .copy {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
  background: var(--bg-grey);
  transition: background 250ms ease-in-out;
}

.license-card .info .copy:hover {
  background: var(--accent);
}

.license-card .info .copy svg {
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5625rem;
  fill: var(--accent);
  transition: fill 250ms ease-in-out;
}

.license-card .info .copy:hover svg {
  fill: var(--bg-main);
}

.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
      1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em -1.8em 0 0em #ffffff;
  }
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
      1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(177, 110, 110, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em -1.8em 0 0em #ffffff;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--bg-main);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 110;
  padding: 20px;
}

.modal .close {
  position: absolute;
  top: 70px;
  right: 120px;
  cursor: pointer;
}

.modal .close svg {
  fill: var(--accent);
}

.image-viewer {
  max-width: 100%;
  width: 1080px;
}

.image-viewer .swiper-container {
  max-width: 100%;
  height: 100%;
}

.image-viewer .swiper-slide {
  background-color: var(--bg-main);
}

.image-viewer .swiper-slide * {
  max-height: 100%;
}

.image-viewer img {
  object-fit: contain;
  z-index: 1;
}

.image-viewer .preview {
  margin-top: 20px;
}

.image-viewer .preview .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.image-viewer .preview .swiper-wrapper {
  justify-content: center;
}

.image-viewer .swiper-lazy-preloader {
  z-index: 0;
}

.viewer-trigger {
  cursor: pointer;
}

.overflow-true {
  overflow: hidden;
}

.ReactModal__Body--open #__next {
  overflow: hidden;
}

.ReactModal__Overlay ul > li:before {
  display: none;
}

.ReactModal__Body--open .ril-toolbar.ril__toolbar {
  background-color: transparent;
}

.ReactModal__Body--open[aria-hidden='true'] {
  overflow: hidden;
}

.ReactModal__Body--open[aria-hidden='true'] #__next {
  overflow: visible;
}

.order-history-table {
  margin-top: 3rem;
  margin-bottom: 2.5rem;
}

.order-history-top-row {
  display: none;
}

.order-history-row.failed .col-5 {
  color: var(--red);
}

.order-history-row.success .col-5 {
  color: var(--green);
}

.order-history-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  color: var(--accent);
  font-size: 1rem;
  line-height: 1.25rem;
  border-bottom: 1px solid var(--bg-borders);
}

.order-history-col {
  flex-wrap: wrap;
  width: 50%;
  margin-bottom: 2rem;
}

.order-history-row .col-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-history-row .col-2,
.order-history-row .col-4 {
  text-align: left;
}

.order-history-row .col-3,
.order-history-row .col-5 {
  text-align: right;
}

.order-history-row .copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
  background: var(--bg-grey);
  transition: background 250ms ease-in-out;
  cursor: pointer;
}

.order-history-row .copy:hover {
  background: var(--accent);
}

.order-history-row .copy svg {
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5625rem;
  fill: var(--accent);
  transition: fill 250ms ease-in-out;
}

.order-history-row .copy:hover svg {
  fill: var(--bg-main);
}

.order-mobile-label {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--main);
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.order-history-row .col-1 .order-mobile-label {
  margin-bottom: 0.2rem;
}

.history-link {
  margin-left: 5px;
  text-decoration: underline;
}

.history-link:hover {
  text-decoration: none;
}

.survey {
  position: fixed;
  bottom: 0;
  left: 1.25rem;
  z-index: 1;
}

.survey-popup {
  display: none;
  position: relative;
  background-color: var(--bg-popup);
}
.survey-mobile-popup {
  display: flex;
  align-items: center;
  /*padding: .625rem;*/
  padding: 0.625rem 1rem;
  background-color: var(--bg-popup);
  border-radius: 30px;
  margin-bottom: 20px;
}
.survey-content {
  display: flex;
  align-items: center;
}

.survey-icon {
  flex-shrink: 0;
}

.survey-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.45rem 0 1.35rem;
  background-color: var(--orange);
  color: var(--accent);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.9rem;
  line-height: 0.95rem;
  font-weight: 700;
  cursor: pointer;
}

.survey-link:hover {
  text-decoration: none;
}

.survey-close {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.survey-close svg {
  width: 100%;
  height: 100%;
}

.survey-mobile-icon {
  width: 2.125rem;
  height: 2.125rem;
  margin-right: 0.625rem;
}

.survey-mobile-title {
  margin-top: 0.2rem;
  color: var(--accent);
  font-size: 0.85rem;
  font-weight: 700;
}

.survey-mobile-close {
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.6rem;
}

.survey-mobile-close svg {
  width: 100%;
  height: 100%;
}

.survey-full {
  position: fixed;
  top: 100%;
  bottom: -100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-main);
  transition: top 250ms ease-in;
}

.survey-mobile-popup._active .survey-full {
  top: 0;
  bottom: 0;
}

.full-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-close {
  position: absolute;
  top: 95px;
  right: 20px;
}

.full-icon {
  margin: 0 auto 1.25rem;
}

.full-icon img {
  object-fit: contain;
}

.full-wrap {
  margin: 0 2.25rem;
  text-align: center;
}

.full-title {
  margin-bottom: 0.625rem;
  color: var(--accent);
  font-size: 1.625rem;
  line-height: 2rem;
  font-weight: 700;
}

.full-text {
  margin-bottom: 1.825rem;
  font-size: 1rem;
  text-align: center;
}

.survey-full .survey-link {
  width: 100%;
}

.settings-title {
  margin-top: 2.5rem;
}

.flag-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1.5rem;
  margin-right: 0;
  margin-bottom: 1.5rem;
  border: 2px solid var(--accent);
  color: var(--accent);
  opacity: 0.4;
  cursor: pointer;
}

.flag-label.active {
  opacity: 1;
  cursor: auto;
}

.flag-label img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.spinner-licenses {
  order: 2;
}

.setup-config {
  scroll-margin-top: 3rem;
}

.password-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.cookie-banner {
  position: fixed;
  bottom: 1px;
  font-size: 0.45rem;
  width: 100vw;
  background: rgba(14, 14, 19, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.6rem 0.8rem 0.4rem;
  backdrop-filter: blur(1.25rem);
  z-index: 100;
}

.cookie-banner > .btn {
  height: 1.6rem;
  width: 100%;
  margin-top: 0.6rem;
  font-size: 0.5rem;
}

.terms_of_service p {
  color: #7ea1b2;
  font-family: Gilroy-Bold, sans-serif;
}
.terms_of_service h3 {
  font-family: Gilroy-Bold, sans-serif;
  font-weight: 700;
}
.terms_of_service h1 {
  font-family: Gilroy-Bold, sans-serif;
  font-weight: 700;
  align-self: center;
  text-align: center;
  font-size: 35px;
}
.terms_of_service table {
  overflow-x: hidden;
  width: 100%;
  color: #7ea1b2;
}
.table_wrap {
  overflow-x: scroll;
  color: #7ea1b2;
}
.terms_of_service table td {
  text-align: left;
  padding: 10px;
  color: #7ea1b2;
  border: solid 1px #7ea1b2;
}
.terms_of_service table tr {
  color: #7ea1b2;
  border: solid 1px #7ea1b2;
}
.terms_of_service table th {
  color: #7ea1b2;
  border: solid 1px #7ea1b2;
}

.previous-versions {
  display: flex;
  flex-direction: column;
}

.previous-versions .left {
  border-bottom: 0.125rem solid var(--accent);
  padding-bottom: 3.75rem;
  margin-bottom: 3.75rem;
}

.previous-versions .back-link {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.previous-versions .back-link > svg {
  fill: rgba(255, 255, 255, 0.5);
  transform: rotate(90deg);
  margin: -2px 5px 0 0;
  width: 1rem;
  height: 1rem;
}

.previous-versions .title {
  font-size: 1.875rem;
  line-height: 52px;
}

.previous-versions .subtitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
}

.previous-versions .agreement {
  font-size: 0.6rem;
  color: rgba(255, 255, 255, 0.5);
}

.previous-versions .agreement a {
  color: #fff;
}

.previous-versions .input svg {
  position: absolute;
  top: 1.1rem;
  transform: translateY(-50%);
}

.previous-versions .input input {
  padding-left: 34px;
  padding-bottom: 10px;
  margin-top: 80px;
  font-size: 1.5rem;
}

.previous-versions .input .clear-btn {
  position: absolute;
  right: 1rem;
  top: 1.1rem;
  transform: translateY(-50%);
  background: 0;
  border: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.previous-versions .input .found {
  margin-top: 1.5rem;
}

.previous-version-item {
  width: 100%;
}

.previous-version-item .item-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.previous-version-item .item-date {
  margin: 0 0 45px 0;
}

.previous-version-item .item-sup {
  font-size: 0.75rem;
  color: #0de7b2;
  margin-left: 1rem;
  position: relative;
  top: -0.5rem;
}

.previous-version-item .item-links {
  width: 100%;
}

.previous-version-item .item-links > a:nth-child(1) {
  padding: 1.25rem 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  box-sizing: border-box;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
}

.previous-version-item .item-links > a:nth-child(2) {
  padding: 1.25rem 0;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  display: flex;
  margin-left: 30px;
  box-sizing: border-box;
  align-items: center;
  font-size: 1rem;
  width: 100%;
}

.previous-versions .load-more {
  color: #ff721c;
  font-size: 1.5rem;
  background: 0;
  border: 0;
  margin: 6rem 0;
  cursor: pointer;
}

.previous-versions .right .col > p {
  color: #fff;
  font-size: 1rem;
}

.previous-versions .right .latest {
  margin-top: 4rem;
}

.previous-versions .right .latest > p {
  margin-bottom: 0;
}

.latest a {
  color: #ff721c;
  display: block;
  margin-top: 10px;
}

.latest a > svg path:first-of-type {
  stroke: #ff721c;
}

.latest a > svg path {
  fill: #ff721c;
}

.soc-container {
  margin-top: 60px;
}

.soc-title {
  margin: 0 0 10px 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #a29dba;
  text-transform: uppercase;
  font-family: 'Gilroy';
}

.soc {
  justify-content: flex-start !important;
  margin: 0 !important;
}

.soc > a {
  background: #1d192c;
  border: 0 !important;
  width: 50px;
  height: 50px;
  border-radius: 14px !important;
  margin-right: 10px;
}

.soc > a > svg {
  transform: scale(0.7);
}

.overview-success-icon {
  width: 32px !important;
  height: 100% !important;
  fill: none !important;
  position: static !important;
  margin-right: 20px;
  flex-shrink: 0;
}

.overview-text {
  display: inline-flex !important;
  align-items: flex-start;
  padding-left: 0 !important;
}

.download-notification-button {
  background: 0;
  border: 0;
  cursor: pointer;
  color: var(--orange);
  font-size: 1.125rem;
  line-height: 1.1875rem;
}

.download-notification {
  flex-direction: row;
  align-items: center;
}

.steps-full-image > div > img {
  border-radius: 30px;
}

.setup-head .steps .text a {
  color: var(--orange);
}

.setup-head .steps .text {
  font-size: 1.3rem;
}

.black-friday {
  display: block;
  width: 100%;
  background: url(/img/black-friday-mobile.jpg) no-repeat top center;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 80px;
  flex-direction: column;
  padding: 40px 0 0 5%;
  height: 450px;
  position: relative;
}

.black-friday:active,
.black-friday:hover {
  text-decoration: none !important;
}

.black-friday-main-page {
  margin-top: 5%;
}

.black-friday-internal-page {
  transform: translateY(0);
  margin-bottom: 2%;
}

.tiger-logo-friday {
  width: 200px;
}

.black-friday-discount {
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  padding: 8px 20px;
  border: 2px solid #fff;
  border-radius: 25px/50%;
  white-space: nowrap;
  position: absolute;
  top: 223px;
  box-sizing: border-box;
  height: 55px;
}

.black-friday-title {
  display: none;
}

.black-friday-title-mobile {
  font-weight: bold;
  color: #fff;
  font-size: 80px;
  line-height: 70px;
  margin-bottom: 130px;
}

.black-friday-code {
  font-size: 25px;
  font-weight: 900;
  color: #000;
  border-radius: 25px/50%;
  background-color: #fff;
  padding: 15px 20px 0px 20px;
  position: absolute;
  top: 292px;
  left: calc(5% + 210px);
  box-sizing: border-box;
  height: 55px;
}

.black-friday-code::after {
  font-size: 11px;
  font-weight: 100;
  content: 'PROMO CODE';
  position: absolute;
  top: 3px;
  left: 0;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .black-friday-title-mobile {
    font-size: 60px;
  }

  .black-friday-discount {
    font-size: 20px;
    border-radius: 20px/50%;
    top: 231px;
    height: 41px;
    padding: 5px 15px 0px 15px;
  }

  .black-friday-code {
    font-size: 20px;
    left: calc(5% + 180px);
    height: 41px;
    top: 301px;
    left: 180px;
    border-radius: 20px/50%;
  }
}
