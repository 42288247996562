@charset "utf-8";

@media only screen and (min-width: 640px) {
  :root {
    font-size: 16px;
  }

  .cookie-banner {
    font-size: 1rem;
  }

  .cookie-banner > .btn {
    font-size: 1.1rem;
    height: 3rem;
  }
}
