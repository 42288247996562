@charset "utf-8";

@media only screen and (min-width: 1020px) {
  :root {
    font-size: 0.833333vw;
  }
  body {
    font-size: 1.25rem;
    line-height: 1.7;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  h2,
  .h2,
  h3,
  .h3,
  h4,
  h5,
  h6 {
    margin-top: 5.625rem;
    margin-bottom: 1.25rem;
  }
  h1,
  .h1 {
    font-size: 4rem;
    line-height: 4.3125rem;
  }
  h2,
  .h2 {
    font-size: 2.25rem;
    line-height: 2.4375rem;
  }
  input,
  select,
  textarea,
  button,
  .input-placeholder {
    font-size: 2.25rem;
    line-height: 2.4375rem;
  }
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='color'],
  input[type='date'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='number'],
  input[type='range'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  textarea,
  select {
    padding-bottom: 1.75rem;
  }
  textarea.autosize {
    height: 4.5rem;
  }
  .btn {
    height: 5.625rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
    border-width: 0.25rem;
  }
  .btn-compact {
    height: 3.75rem;
  }
  .checkbox {
    width: 1.875rem;
    height: 1.875rem;
  }
  .check {
    font-size: 1.125rem;
    line-height: 1.1875rem;
    margin: 0;
  }
  .check label {
    padding-top: 0.34375rem;
    padding-bottom: 0.34375rem;
    padding-left: 3.4375rem;
  }
  .check .checkbox {
    margin: -0.9375rem 0;
  }
  .check + .check {
    margin-top: 2.5rem;
  }
  .check .input {
    margin-top: 1.875rem;
  }
  .check.check-with-select .input {
    margin-top: 0.28125rem;
  }
  .check .input input,
  .check .input select {
    padding-bottom: 1.25rem;
  }
  .check.check-inline-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .check.check-inline-select .input {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-left: 3.125rem;
  }
  .check.big-desk.check-inline-select .input {
    margin-top: 0.1875rem;
  }
  .input-label:not(.big) {
    font-size: 1.125rem;
    line-height: 1.1875rem;
    margin-bottom: 1.5625rem;
  }
  .input-info {
    font-size: 1.125rem;
    line-height: 1.1875rem;
    margin-top: 1.875rem;
  }
  .subinput-info {
    font-size: 1.125rem;
    line-height: 1.875rem;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .form-title {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  .form-row {
    margin-bottom: 5rem;
  }
  .form-row.margin-top {
    margin-bottom: 0;
    margin-top: 5rem;
  }
  .form-row.margin-small {
    margin-bottom: 3.75rem;
  }
  .form-row.margin-min {
    margin-bottom: 3.125rem;
  }
  .form-row.settings-row {
  }
  .form-date {
    margin-left: -3.125rem;
  }
  .form-date-col,
  .form-date-col:last-child {
    padding-left: 3.125rem;
    width: 33.333333%;
  }
  .form-submit {
    margin-top: 5rem;
  }
  .submit-info {
    font-size: 0.875rem;
    line-height: 0.9375rem;
    text-align: inherit;
    margin-top: 3.75rem;
  }
  .notification {
    top: 6.25rem;
    font-size: 1.125rem;
    line-height: 1.1875rem;
    min-height: 4rem;
  }
  .header {
    background: var(--bg-main);
    border-bottom: 1px solid var(--bg-borders);
  }
  .header .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: calc(6.25rem - 1px);
    max-width: 1520px;
    width: 100%;
    margin: 0 auto;
  }
  .headline {
    background: none;
    border: 0;
    height: auto;
    padding: 0 0 0 3.125rem;
    display: block;
  }
  .header .logo {
    width: 15.5625rem;
  }
  .header .logo img {
    height: 2.5rem;
  }
  .toggle-menu {
    display: none;
  }
  .wrap-menu {
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background: none;
    overflow: visible;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-transition: none !important;
    transition: none !important;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .licenses-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    padding: 0;
  }

  .licenses-toggle {
    color: white;
    cursor: pointer;
  }

  .licenses-toggle .arr svg {
    fill: #ff721c;
  }

  .licenses-toggle .toggle-rotated svg {
    transform: rotate(180deg);
  }

  .licenses-toggle .filters {
    position: absolute;
    right: 0;
    padding: 0.75rem 1.75rem;
    display: flex;
    flex-direction: column;
    background: #19191d;
    box-sizing: border-box;
  }

  .licenses-toggle .filters .filter-active {
    color: #ff721c;
  }

  .licenses-filter {
    color: #ff721c;
  }

  .no-licenses {
    color: white;
    font-size: 1.5rem;
    white-space: nowrap;
  }

  .pay-with-stripe {
    flex-direction: row;
    justify-content: space-between;
  }

  .menu-padding {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .menu-section {
    font-size: 1rem;
    line-height: 1.1875rem;
  }
  .menu-section ul li a {
    opacity: 1;
    font-size: 1rem;
  }
  .menu-section:not(.menu-main):not(.menu-profile):not(.menu-lang) {
    display: none;
  }
  .menu-section:not(:first-child) {
    margin-top: 0;
  }
  .menu-line,
  .menu-title,
  .menu-profile-name {
    display: none;
  }
  .menu-main {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .menu-lang {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 3.125rem;
    padding-left: 0;
    margin-bottom: 0.2rem;
  }
  .menu-main ul,
  .menu-lang ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .menu-main li {
    -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .menu-main.hover li.active:not(:hover) {
    opacity: 0.5;
  }
  .menu-main li,
  .menu-lang li {
    margin: 0 !important;
    padding: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .menu-main li > *,
  .menu-lang li > * {
    display: block;
    padding: 0.25rem 1.40625rem;
  }
  .menu-main li:first-child,
  .menu-lang li:first-child {
    margin-left: 40px !important;
  }
  .menu-main li:last-child,
  .menu-lang li:last-child {
    margin-right: auto !important;
  }
  .menu-main li.link-download ~ li:last-child {
    margin-right: 0 !important;
  }
  .menu-main li.link-download {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin: 0 auto !important;
  }
  .menu-main li:not(.link-download) > * {
    color: var(--main);
    -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .menu-main li:not(.link-download) > a:hover,
  .menu-main li:not(.link-download) > .toggle:hover {
    color: var(--accent);
  }
  .menu-main li.active > *,
  .menu-lang li > * {
    color: var(--accent);
  }
  .menu-main li.for-mob {
    display: none;
  }
  .menu-main .pic {
    display: inline-block;
    margin-top: -0.1875rem;
    margin-bottom: -0.1875rem;
  }
  .menu-section li > .toggle {
    margin: 0;
    padding: 0.2rem 2.5rem 0 0;
  }
  .menu-section li > .toggle .arr {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-right: 0;
  }
  .menu-profile {
    position: fixed;
    top: 6.25rem;
    right: 0;
    z-index: 100;
    height: calc(100% - 6.25rem);
    overflow: hidden;
    overflow-y: auto;
    background: var(--bg-main);
    width: 21.875rem;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  .open-user-menu .menu-profile {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .page-fader {
    position: fixed;
    z-index: 90;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black);
    -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  .open-user-menu .page-fader,
  .open-lang-menu .page-fader {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.8;
  }
  .menu-profile ul {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    margin: 0 1.25rem !important;
    padding: 3.125rem 1.875rem;
  }
  .menu-profile ul.top {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .menu-profile ul.bottom {
    padding-top: 1.5625rem;
    padding-bottom: 1.875rem;
    border-top: 1px solid var(--bg-borders);
  }
  .menu-profile li:not(:first-child) {
    margin-top: 1.875rem;
  }
  .menu-profile li > label {
    display: block;
    margin-right: 1.875rem;
  }
  .menu-lang-sub {
    left: auto;
    right: 0;
    top: 6.25rem;
    z-index: 100;
    width: 34.375rem;
    height: calc(100% - 6.25rem);
    font-size: 2.25rem;
    line-height: 2.4375rem;
  }
  .menu-lang-sub .back {
    display: none;
  }
  .menu-lang-sub ul {
    padding: 2.1875rem 2.1875rem 2.1875rem 5rem;
  }
  .menu-lang-sub li {
    padding: 0.9375rem 0;
  }
  .head-login-links,
  .head-user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    min-width: auto;
    width: 140px;
    height: 40px;
    background: #1d192c;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 145%;
    font-family: 'Gilroy';
    margin: auto 3.125rem auto 0;
  }
  .footer {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    padding: 10rem 3.125rem 6.25rem 3.125rem;
    font-size: 1.5rem;
    line-height: 2.1rem;
    color: var(--accent);
  }
  .footer .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 0 -3.125rem;
  }
  .footer .grid > * {
    padding: 0 0 7.8125rem 3.125rem;
    margin: 0;
  }
  .footer .logo {
    display: block;
  }
  .footer .logo svg {
    height: 3.125rem;
  }
  .foot-title {
    color: var(--grey);
    margin-bottom: 1.5625rem;
  }
  .foot-contact {
    font-size: 1.5rem;
    line-height: 1.625rem;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .foot-contact .foot-title {
    margin-bottom: 0.625rem;
    text-transform: none;
  }
  .foot-contact .soc {
    margin: 0 0 0 5.3125rem;
  }
  .foot-contact .soc > * {
    width: 3.75rem;
    height: 3.75rem;
    margin-left: 0.9375rem;
  }
  .foot-link-button a {
    width: 90%;
    font-size: 1.125rem;
  }
  .foot-links {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 44%;
  }
  .foot-links .col {
    width: 50%;
    margin-left: auto;
  }
  .foot-links .col:nth-child(2) {
    margin-left: 2.5rem;
  }
  .foot-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    line-height: 2.1rem;
    max-width: 40rem;
  }
  .foot-copy {
    color: var(--grey);
    margin-top: 1.5rem;
  }
  .content {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    padding: 14.25rem 3.125rem 0 3.125rem;
  }
  .page-with-sidebar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    /*padding: 0 3.125rem;*/
  }
  .side-menu {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 26.5rem;
    padding: 0 3.125rem 0 0;
    position: -webkit-sticky;
    position: sticky;
    top: 9.375rem;
    margin: 0 0 1.25rem 0;
    font-size: 1.875rem;
    line-height: 2rem;
  }
  .side-menu .swiper-container {
    padding: 0;
  }
  .side-menu .swiper-wrapper {
    display: block !important;
    -webkit-transform: none !important;
    transform: none !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
  }
  .side-menu .swiper-slide {
    padding: 0;
    width: auto !important;
    margin: 0;
  }
  .side-menu .swiper-slide:not(:first-child) {
    margin-top: 1.5625rem;
  }
  .side-menu .swiper-slide.active {
    padding-right: 0;
  }
  .side-menu .swiper-slide.active:after {
    display: none;
  }
  .side-menu-title {
    display: block;
    margin-top: 10px;
  }
  .page-content {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: calc(100% - 26.5rem);
    margin: 0 auto;
  }
  .page-personal {
    margin-top: -1.25rem;
    padding: 0 0 3.125rem 0;
  }
  .page-personal h1,
  .page-personal .h1 {
    font-size: 2.75rem;
    line-height: 3.625rem;
  }
  .page-personal h2,
  .page-personal .h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  .page-personal .side-menu {
    width: 28.125rem;
    margin-top: 0;
    margin-bottom: -0.5rem;
  }
  .page-personal .page-content {
    width: calc(100% - 28.125rem);
    margin-left: 0;
  }
  .page-personal .page-content:not(.wide) {
    max-width: 62.5rem;
  }
  .big-title {
    font-size: 1.25rem;
    line-height: 2.125rem;
    padding: 0 0 2rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .big-title .title-wrap h1 {
    margin: 0;
  }
  .big-title .title-wrap,
  .big-title .title-wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-size: 4rem;
    line-height: 1.1;
    margin: 0;
  }
  .big-title h2,
  .big-title .h2 {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0;
  }
  .big-title .text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 40.875rem;
    margin: 0 0 0 1.25rem;
  }
  .features-list {
    padding: 3.625rem 0 7.5rem 0;
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
  .features-list ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 0 -5.625rem -6.875rem;
  }
  .features-list li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0;
    padding: 0 0 5.625rem 6.875rem;
    width: 33.333333%;
  }
  .features-list li:not(:first-child) {
    margin-top: 0;
  }
  .features-list li:before {
    margin-bottom: 2rem;
  }
  .features-list h2,
  .features-list .h2 {
    margin-bottom: 0.9375rem;
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
  .features {
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.875rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: -1px;
  }
  .features .el {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
    padding: 3.125rem 2.5rem 6.25rem 2.5rem;
    border-width: 1px 0 0 1px;
  }
  .features .el:nth-child(odd) {
    border-left-width: 0;
    padding-left: 0;
  }
  .features .el:nth-child(even) {
    padding-right: 0;
  }
  .features .el:nth-last-child(1),
  .features .el:nth-last-child(2) {
    padding-bottom: 0;
  }
  .features .el .anchor {
    position: absolute;
    top: calc(-6.25rem + 1px);
    left: 0;
    right: 0;
    height: 0;
    pointer-events: none;
  }
  .features h2,
  .features .h2 {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 2.6rem;
    font-weight: 400;
  }
  .features .image {
    margin-bottom: 3.125rem;
  }
  .features ul {
    font-size: 1.125rem;
    line-height: 2rem;
    margin-top: 2.75rem;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 4.375rem;
    -moz-column-gap: 4.375rem;
    column-gap: 4.375rem;
  }
  .features li {
    padding: 0 0 1.25rem 3.125rem;
    font-size: 0.875rem;
  }
  .features li svg {
    top: 0.0625rem;
    width: 1.875rem;
    height: 1.875rem;
  }
  .info-links {
    padding-top: 5.625rem;
    padding-bottom: 3.125rem;
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .info-links h2,
  .info-links .h2 {
    font-size: 4rem;
    line-height: 4.3125rem;
    padding-bottom: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .info-links ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: -0.9375rem 0 0 -0.9375rem;
  }
  .info-links li {
    margin: 0.9375rem 0.9375rem 0 0.9375rem;
  }

  .contacts-wrap {
  }

  .contacts-wrap .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5.5rem 0 0;
  }

  .contacts-wrap .top .title {
    font-size: 2.75rem;
    line-height: 4.3125rem;
  }

  .contacts-wrap .top .subtitle {
    font-size: 1.25rem;
  }

  .contacts-wrap .top .r {
    padding: 0;
  }

  .contacts-item {
    font-size: 1.88rem;
    line-height: 2.25rem;
  }

  .contacts-wrap .bottom {
    padding: 7.33rem 0 0;
  }

  .soc-wrap {
    flex-wrap: nowrap;
  }

  .soc-item-wrap {
    flex: auto 1 0;
  }

  .soc-item-wrap:nth-child(odd) {
    border-right: none;
  }

  .soc-item-wrap + .soc-item-wrap {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .soc-item {
    height: 23.125rem;
    padding: 2rem 0 0;
  }

  .soc-item svg {
  }

  .soc-item-text {
    margin: 5.625rem 0 0;
    font-size: 1.125rem;
  }

  .contacts {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .contacts .l,
  .contacts .r {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .contacts .l > *,
  .contacts .r > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .contacts .l {
    padding: 3.125rem 3.125rem 3.125rem 0;
  }
  .contacts .r {
    padding: 4.375rem 0 0 0;
    border-left: 1px solid var(--accent);
  }
  .contacts .big {
    font-size: 4rem;
    line-height: 7.75rem;
    margin-bottom: 3.125rem;
  }
  .contacts .text {
    font-size: 1.5rem;
    color: var(--accent);
    max-width: 31.0625rem;
  }
  .contacts .links {
    font-size: 1.5rem;
    margin-top: 0;
    margin-left: 0;
  }
  .contacts .links li {
    padding-left: 3.125rem;
  }
  .contacts .links small {
    display: none;
  }
  .contacts .soc {
    margin-top: 4.375rem;
  }
  .contacts .soc > * {
    font-size: 1.125rem;
    padding-bottom: 3.125rem;
  }
  .contacts .soc > *:before {
    padding-top: 69%;
  }
  .contacts .soc svg {
    top: calc(50% - 2.5rem);
    width: 3.25rem;
    height: 3.25rem;
    margin: -1.625rem;
  }

  .feedback-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .feedback-wrap .top {
    width: 44rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 3rem;
  }
  .feedback-wrap .top .l {
    margin-bottom: 3.125rem;
  }
  .feedback-wrap .contacts-item {
    font-size: 1.5rem;
  }
  .feedback-wrap .feedback {
    width: 47.5rem;
  }
  .feedback .form-row {
    margin-bottom: 3.125rem;
  }

  .info-table {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .page-login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.25rem 0 6.25rem 0;
  }
  .login-title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 1.5rem;
    margin-bottom: 6.3rem;
  }
  .login-title h1,
  .login-title .h1 {
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: -0.02em;
  }
  .login-title .h1 {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .login-title .h1 .slash {
    margin: 0 0.35em;
  }
  .login-title .text {
    font-size: 1.875rem;
    line-height: 2rem;
    margin-top: 1.25rem;
    margin-right: 10rem;
  }
  .login-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 56.3rem;
    width: 100%;
  }
  .my-ava {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .my-ava .img {
    width: 9.375rem;
    height: 9.375rem;
    margin: 0 3.125rem 0 0;
  }
  .my-ava .form-title {
    display: block;
    margin-bottom: 1.5625rem;
  }
  .my-ava .text {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0;
  }
  .my-ava .links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .my-ava .links .btn {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    height: 3.75rem;
    min-width: 12.5rem;
  }
  .links-2fa {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid var(--accent);
    margin: 3.75rem 0 0 0;
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
  .links-2fa .col {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0;
    margin: 0;
    border: 0;
  }
  .links-2fa h2,
  .links-2fa .h2 {
    margin-top: 0;
  }
  .links-2fa .text {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    padding-top: 3.125rem;
    padding-bottom: 2.75rem;
    margin: 0;
  }
  .links-2fa .btn {
    height: 5rem;
  }
  .links-2fa .col:not(:first-child) .text {
    padding-left: 1.875rem;
    border-left: 1px solid var(--accent);
  }
  .links-2fa .col:not(:last-child) .text {
    padding-right: 1.875rem;
  }
  .links-2fa .col:not(:first-child) .btn {
    margin-left: 1px;
  }
  .top-index {
    margin: -14.25rem -3.125rem 0 -3.125rem;
    padding-top: 6.25rem;
  }
  .top-index .top {
    padding: 5.6875rem 6.25rem;
  }
  .top-index video {
    -o-object-position: top center;
    object-position: top center;
  }
  .top-index .play-btn {
    top: auto;
    bottom: 0;
    margin: 7.1875rem 11.25rem;
    width: 8.125rem;
    height: 8.125rem;
  }
  .top-index .play-btn svg {
    width: 2.75rem;
    height: 2.75rem;
  }
  .top-index .text-wrap {
    max-width: 56.25rem;
    font-size: 1.5rem;
    line-height: 1.5;
    color: hsla(0, 0%, 100%, 0.7);
  }
  .top-index h1,
  .top-index .h1 {
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: -0.02em;
    margin-bottom: 0.9375rem;
    max-width: 41rem;
  }
  .top-index .text {
    max-width: 45rem;
  }

  .top-index .inputs {
    margin-top: 3.125rem;
    max-width: 48.125rem;
  }
  .top-index .inputs .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .top-index .inputs .input {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .top-index .inputs .input + .btn {
    margin: 0 0 0 1.25rem;
    width: auto;
  }
  .top-index .inputs .btn {
    width: 31.25rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    height: 4.375rem;
    min-width: 15.625rem;
    margin: 0;
    font-size: 0.875rem;
    line-height: 0.9375rem;
  }
  .top-index .input input {
    font-size: 2.25rem;
    line-height: 2.4375rem;
    padding-bottom: 1.25rem;
  }
  .top-index .inputs .submit-info {
    margin-top: 2.5rem;
  }
  .top-index .bottom {
    display: block;
    visibility: visible;
  }
  .why-us {
    padding-top: 5.625rem;
    padding-bottom: 1.25rem;
    font-size: 2.25rem;
    line-height: 3.375rem;
    color: var(--accent);
  }
  .why-us .head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-width: 0.125rem;
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .why-us .head .btn {
    width: auto;
    min-width: 27.5rem;
    margin: 0 0 0 2.5rem;
  }
  .why-us h2,
  .why-us .h2 {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0;
    font-size: 4rem;
    line-height: 6rem;
  }
  .why-us .thumbs {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3.75rem;
  }
  .why-us .thumbs .swiper-container {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .why-us .main {
    margin-left: -3.125rem;
    margin-right: -3.125rem;
  }
  .why-us .main .swiper-slide {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
  }
  .why-us .main .text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 35%;
    padding: 0 3.125rem 0 0;
    font-size: 1.25rem;
    line-height: 2.11rem;
  }
  .why-us .main .text h3,
  .why-us .main .text .h3 {
    font-size: 2.25rem;
    line-height: 3rem;
  }
  .why-us .main .image {
    width: 52%;
    margin: 0;
  }
  .why-us .nav,
  .why-us .swiper-pagination {
    display: none;
  }
  .multi-coverage {
    display: flex;
    align-items: center;
    padding-top: 0;
    font-size: 1.5rem;
    line-height: 1.5;
    margin-top: 10rem;
  }
  .multi-coverage h2,
  .multi-coverage .h2 {
    font-size: 3.5rem;
    line-height: 4rem;
    margin-bottom: 1.875rem;
  }
  .multi-coverage .head {
    max-width: 600px;
  }
  .multi-coverage .bg {
    width: 4rem;
    height: 3.5rem;
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .multi-coverage .bg li {
    font-size: 0.8rem;
    line-height: 1rem;
    width: 8.5rem;
    height: 8.5rem;
    box-shadow: 0 0 0 1rem var(--bg-main);
  }
  .connections {
    padding-top: 5.625rem;
    padding-bottom: 6.25rem;
  }
  .connections h2,
  .connections .h2 {
    font-size: 4rem;
    line-height: 6.3125rem;
    margin-bottom: 1.125rem;
  }
  .connections h2 sup,
  .connections .h2 sup {
    font-size: 2.25rem;
    line-height: 2.4375rem;
    letter-spacing: normal;
  }
  .connections ul {
    margin: 0;
  }
  .connections li {
    width: 20%;
    padding: 4.6rem;
    height: 12.25rem;
  }
  .connections li:nth-child(2n) {
    border-right-width: 1px;
  }
  .connections li:nth-child(5n) {
    border-right-width: 0;
  }
  .connections li:nth-child(3),
  .connections li:nth-child(4),
  .connections li:nth-child(5) {
    border-top-width: 1px;
  }
  .connections li:nth-child(5),
  .connections li:nth-child(10) {
    border-right-width: 1px;
  }
  .connections li img {
    max-height: 4.375rem;
  }
  .download-now {
    margin-left: -3.125rem;
    margin-right: -3.125rem;
    padding: 11rem 3.125rem 11rem 3.125rem;
    background-image: -webkit-linear-gradient(
        top,
        rgba(13, 13, 17, 1) 0%,
        rgba(13, 13, 17, 0) 50%,
        rgba(13, 13, 17, 1) 100%
      ),
      url(/img/download-l.png), url(/img/download-r.png);
    background-image: linear-gradient(
        to bottom,
        rgba(13, 13, 17, 1) 0%,
        rgba(13, 13, 17, 0) 50%,
        rgba(13, 13, 17, 1) 100%
      ),
      url(/img/download-l.png), url(/img/download-r.png);
    background-repeat: repeat-x, no-repeat, no-repeat;
    background-size: 100% 100%, auto 29.125rem, auto 29.125rem;
    background-position: 0 0, 0 50%, 100% 50%;
    text-align: center;
  }
  .download-now h2,
  .download-now .h2 {
    font-size: 4rem;
    line-height: 1.1;
  }
  .download-now .inputs {
    margin: 0 auto;
    max-width: 48.125rem;
  }
  .download-now .inputs .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .download-now .inputs .input {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .download-now .inputs .input + .btn {
    margin: 0 0 0 1.25rem;
    width: auto;
  }
  .download-now .inputs .btn {
    width: 31.25rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    height: 4.375rem;
    min-width: 15.625rem;
    margin: 0 auto;
    font-size: 0.875rem;
    line-height: 0.9375rem;
  }
  .download-now .input input {
    font-size: 2.25rem;
    line-height: 2.4375rem;
    padding-bottom: 1.25rem;
  }
  .download-now .inputs .submit-info {
    margin-top: 3.125rem;
  }
  .download-page {
    padding-bottom: 3.125rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .download-page .l {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 54.945%;
    border: 0;
    padding: 0;
  }
  .download-page .r {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 34.065%;
    padding: 0;
  }
  .download-page .big-title {
    margin-bottom: 0.625rem;
  }
  .download-page .head {
    max-width: 58.125rem;
    font-size: 1.5rem;
    line-height: 1.875rem;
    letter-spacing: normal;
    margin-bottom: 3.125rem;
  }
  .download-page .input input {
    font-size: 3rem;
    line-height: 3.25rem;
  }
  .download-page .submit {
    margin-top: 3.125rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .download-page .submit .submit-info {
    margin-top: 2.5rem;
  }
  .download-page .submit-l {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 60.9%;
  }
  .download-page .submit-r {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 39.1%;
    font-size: 1rem;
    line-height: 1.6rem;
    text-align: left;
    margin: 0;
    padding: 0 0 0 1.875rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 5.625rem;
  }
  .download-page .submit-r--secondary {
    min-height: 3.75rem;
  }
  .download-page .submit-r strong {
    color: var(--accent);
    font-weight: normal;
  }
  .download-page .submit-r p {
    margin: 0.9375rem 0;
  }
  .download-page .info {
    font-size: 1.25rem;
    line-height: 2.125rem;
    letter-spacing: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 0 -3.625rem -6.25rem;
  }
  .download-page .info .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
    padding: 2.625rem 0 3.625rem 6.25rem;
    margin: 0 !important;
  }
  .download-page .info .col.wide {
    width: 100%;
  }
  .download-page .info-title {
    font-size: 1rem;
    line-height: 1.0625rem;
    letter-spacing: 0.02em;
    text-transform: none;
    margin-bottom: 1.25rem;
  }
  .download-page .info p {
    margin-top: 1.25rem;
  }
  .download-page .info sup {
    font-size: 0.875rem;
    line-height: 0.9375rem;
  }
  .setup-head {
    padding-bottom: 4.375rem;
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .setup-head .big-title {
    margin-bottom: 3.75rem;
  }
  .setup-head .nav {
    display: none;
  }
  .setup-head .steps {
    margin: 0;
  }
  .setup-head .steps .swiper-wrapper {
    margin: 0 -2.1875rem;
    width: auto !important;
  }
  .setup-head .swiper-slide {
    width: 25%;
    padding: 0 2.1875rem;
  }
  .setup-head .steps .num {
    display: block;
  }
  .setup-config {
    padding-top: 5.625rem;
    padding-bottom: 4.375rem;
  }
  .setup-config .big-title {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
  }
  .setup-config .big-title h2,
  .setup-config .big-title .h2 {
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: -0.02em;
  }
  .setup-config .big-title .text {
    margin-bottom: 1rem;
    margin-top: 1rem;
    max-width: 33.375rem;
  }
  .setup-config .setup-group {
    margin-bottom: 6.5rem;
    border: 0;
  }
  .setup-config .setup-group .list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 0;
  }
  .setup-config .setup-group .link {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.625rem;
    height: 6.625rem;
    overflow: hidden;
    padding: 0 1.25rem;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    color: var(--accent);
  }
  .setup-config .setup-group .link:not(:first-child) {
    border-left: 1px solid var(--bg-borders);
  }
  .setup-config .setup-group .link.active {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    color: var(--bg-main);
    cursor: default;
    background: var(--accent);
  }
  .setup-config .setup-group:not(.open) .link:not(.active) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .setup-config .setup-group .link svg {
    display: none;
  }
  .setup-config .side-menu {
    margin-top: 0;
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
  }
  .setup-config .side-menu .swiper-slide:not(:first-child) {
    margin-top: 0.9375rem;
  }
  .setup-config .page-with-sidebar {
    padding-left: 0;
    padding-right: 0;
  }
  .setup-config .side-menu.js_tabs .swiper-wrapper {
    margin: 0;
    overflow: hidden;
  }
  .text-page {
    padding-top: 0;
    padding-bottom: 0;
  }
  .text-page li:before {
    top: 2.2rem;
  }
  .text-page .side-menu {
    font-size: 1.875rem;
    line-height: 2rem;
  }
  .zoom-pic {
    width: 2.5rem;
    height: 2.5rem;
    margin: 1.25rem;
  }
  .config-section-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin-bottom: 6.25rem;
    padding-bottom: 3.125rem;
  }
  .config-section-head .image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.69%;
    margin: 0;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .config-section-head .text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.31%;
    padding-right: 9.375rem;
  }
  .config-section-head h3,
  .config-section-head .h3 {
    font-size: 3.5rem;
    line-height: 1.2;
    margin-bottom: 1.875rem;
  }
  .config-section-head .links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .config-section-head .links .btn {
    width: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0;
    height: 4.375rem;
  }
  .config-section-head .links .btn:not(:first-child) {
    margin-left: 1.875rem;
  }
  .config-section-head .links .btn-secondary {
    border-color: var(--accent);
    text-transform: uppercase;
  }
  .config-descr {
    font-size: 1.125rem;
    line-height: 1.875rem;
    letter-spacing: normal;
  }
  .config-descr h3,
  .config-descr .h3 {
    margin-top: 0;
    font-size: 3.5rem;
    line-height: 1.2;
  }
  .config-descr .image {
    margin-bottom: 3.125rem;
    pointer-events: all;
  }
  .config-descr .image.wid-big {
    max-width: 50rem;
  }
  .config-descr .image.wid-medium {
    max-width: 34.375rem;
    /*max-width: 80%;*/
    /*margin: 0 auto 5rem;*/
  }
  .config-descr .image.wid-small {
    max-width: 21.875rem;
    margin-top: 3.125rem;
  }

  .buying-license-title {
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    margin-bottom: 3.125rem;
    color: #fff;
  }

  .border-string {
    width: 100%;
    border-bottom: 1px solid var(--bg-borders);
  }
  .prices {
  }
  .prices .big-title {
    margin-bottom: 0;
    padding-bottom: 3rem;
  }
  .prices .big-title .text {
    margin-bottom: 2rem;
    min-width: 21.75rem;
    margin-top: 1rem;
  }
  .prices .big-title ul {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.625rem;
    margin: 0;
    flex-wrap: nowrap;
  }
  .prices .big-title li {
    padding: 0.125rem 0 0.125rem 3.125rem;
    margin: 0 0 1.875rem 0;
  }
  .prices .big-title li:first-child {
    margin-right: 1.875rem;
  }
  .prices .big-title li svg {
    width: 1.875rem;
    height: 1.875rem;
  }
  .prices .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .prices .grid-player {
    margin-top: 7.5rem;
    padding-top: 0;
    padding-bottom: 8rem;
    border-top: none;
    /*border-bottom: 1px solid var(--bg-borders);*/
  }
  .grid-title {
    border-bottom: 0.063rem solid var(--bg-borders);
    padding-bottom: 1.875rem;
    width: 100%;
    text-align: left;
  }
  .prices .grid-title span {
    font-size: 2.25rem;
    line-height: 2.438rem;
    color: #fff;
  }
  .prices .big-title .title-wrap h1 {
    font-size: 4rem;
    line-height: 3.375rem;
  }
  .prices .big-title .title-wrap .detail-link {
    font-size: 1.25rem;
    line-height: 2.125rem;
    margin-top: 0.625rem;
    color: #ff721c;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  .prices .grid .l,
  .prices .grid .r {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%;
  }
  .prices .grid .l {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .prices .grid .r {
    margin: 0;
    padding: 0;
    border-width: 0 0 0 1px;
  }
  .prices .grid .l-info,
  .prices .grid .r-info {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
    margin-top: 3.125rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
  .prices .grid .l-info {
    padding-right: 2.5rem;
  }
  .prices .grid .r-info {
    padding-right: 2.5rem;
  }
  .prices .grid .r-info-detailed {
    opacity: 0;
  }
  .prices .grid-player .l-info {
    display: flex;
  }
  .prices .grid .r-info {
    border-bottom: none;
    margin-top: 1.875rem;
    padding-right: 2.5rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .prices .grid .r-info:last-child {
    margin-top: 1.875rem;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .prices .grid-player .r {
    border-color: transparent;
  }
  .prices .grid-player .r-info {
    padding-left: 0;
  }
  .prices .grid-player .l .price {
    padding-top: 0;
    text-align: left;
    height: 100%;
  }
  .prices .grid-player .r .time {
    width: 100%;
    height: 100%;
    padding-top: 1.6rem;
    text-align: left;
  }
  .prices .grid-player .r .price {
    height: 100%;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 1.25rem;
  }
  .prices .grid-player .player-title {
    text-transform: initial;
  }

  .prices .thumbs {
    display: block;
  }
  .prices .thumbs .swiper-slide {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition-property: color, background;
    transition-property: color, background;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .prices .thumbs .swiper-slide strong {
    -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .prices .thumbs .swiper-slide {
    border-left: 1px solid var(--bg-borders);
  }
  .prices .thumbs .swiper-slide:nth-child(4) {
    /*border-right: 1px solid var(--bg-borders);*/
  }
  .prices .thumbs .swiper-slide.swiper-slide-thumb-active {
    cursor: default;
    color: var(--bg-main);
    background: var(--accent);
  }
  .prices .thumbs .swiper-slide.swiper-slide-thumb-active strong {
    color: var(--green-dark);
    font-weight: bold;
  }
  .prices .main {
    margin: 0;
  }
  .green {
    margin-bottom: 0;
  }
  .prices .main .nav {
    display: none;
  }
  .prices .main .swiper-container {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .prices .main .swiper-container-fade .swiper-wrapper,
  .prices .main .swiper-container-fade .swiper-slide {
    background: var(--bg-main);
  }
  .prices .main .swiper-container ~ .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(100% - 3.125rem);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .prices .main .swiper-slide {
    padding: 0;
    opacity: 0;
    border-left: 0.063rem solid var(--bg-borders);
  }
  .prices .main .plan .time {
    display: none;
  }
  .prices .grid .licenseBuy {
    border-left: 0;
  }
  .prices .grid .licenseBuyLeft {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .detailed-mobile {
    display: none;
  }
  .detailed {
    display: block;
    padding-bottom: 7.5rem;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  }
  .detailed-title {
    font-size: 3rem;
    line-height: 3.25rem;
    color: #fff;
    font-weight: 400;
  }
  .detailed-wrap {
    width: calc(100% + 3.75rem);
    margin-top: 3.75rem;
    position: relative;
    left: -1.875rem;
  }
  .detailed-header-table {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-column-gap: 0.625rem;
    padding: 0 1.875rem;
    margin-bottom: 2.5rem;
  }
  .detailed-header-table .item {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.875rem;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
  }
  .detailed-header-table .item:first-child {
    text-align: left;
  }
  .detailed-table {
  }
  .table-item {
    padding: 1.875rem;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    align-items: center;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 1.25rem;
  }
  .table-item:nth-child(2n) {
    background: transparent;
  }
  .table-item .text-wrap {
    display: flex;
    flex-direction: column;
  }
  .table-item .text-wrap .show-more {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #ff721c;
    font-size: 1.125rem;
    line-height: 1.875rem;
    margin-top: 0.938rem;
  }
  .table-item .text-wrap .show-more svg {
    width: 0.75rem;
    height: 0.5rem;
    margin-left: 0.563rem;
  }
  .table-item .text-wrap .show-less {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #ff721c;
    font-size: 1.125rem;
    line-height: 1.875rem;
    margin-top: 0.938rem;
  }
  .table-item .text-wrap .show-less svg {
    width: 0.75rem;
    height: 0.5rem;
    margin-left: 0.563rem;
    transform: rotateX(180deg);
  }
  .table-item .text-wrap .title {
    font-size: 1.5rem;
    line-height: 150%;
    color: #fff;
    margin-bottom: 0.313rem;
  }
  .table-item .text-wrap .subTitle {
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: rgba(255, 255, 255, 0.5);
    height: max-content;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    transition: 200ms linear;
  }
  .table-item .text-wrap .subTitleFull {
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: rgba(255, 255, 255, 0.5);
    height: max-content;
    overflow: initial;
    word-break: break-word;
    display: block;
  }
  .table-item .text-wrap .subTitleFull svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.2rem;
    margin-top: 0.1rem;
  }
  .subTitleItem {
    color: white;
  }
  .table-item .icon-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .table-item .icon-wrap .icon-text {
    margin-top: 0.688rem;
    font-size: 0.875rem;
    line-height: 0.938rem;
  }
  .table-item .icon-wrap svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .plan {
    text-align: center;
    padding-top: 0;
  }
  .plan .discount-wrap {
    display: none;
  }
  .grid-player .plan .plan-player .text {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .plan-player .price-ul-wrap {
    display: flex;
    width: inherit;
  }
  .plan .btn {
    margin-top: 3.75rem;
  }
  .plan-player .btn {
    margin-top: 3rem;
  }
  .plan .time,
  .prices .thumbs .swiper-slide,
  .checkout-plans .list .bg {
    text-align: center;
    height: 7.75rem;
    font-size: 1.25rem;
    line-height: 2.125rem;
    letter-spacing: normal;
    padding-top: 2.5rem;
    display: block;
  }
  .plan .time strong,
  .prices .thumbs .swiper-slide strong,
  .checkout-plans .list .bg strong {
    font-size: 1.125rem;
    line-height: 1.875rem;
    margin-top: 0;
  }
  .plan .price {
    padding-top: 5rem;
    height: 17.8125rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .plan .price sup {
    font-size: 2.25rem;
    line-height: 2.4375rem;
    margin-right: 0.6875rem;
  }
  .plan,
  .plan .text {
    align-items: center;
  }

  .plan .priceLicenseBuy {
    display: flex;
    align-items: flex-start;
  }

  .plan .textLicenseBuy {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .your-account {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    color: #fff;
    margin-bottom: 88px;
  }

  .your-account span:nth-child(2) {
    font-size: 28px;
  }

  .plan .price-text .ulPriceLicenseBuy {
    padding-top: 5rem;
  }
  .plan .price-text {
    padding-top: 1rem;
    width: 100%;
    text-align: left;
  }
  .plan .price-text .price {
    display: flex;
    align-items: flex-start;
  }
  .plan .plan-player {
    align-items: flex-start;
  }
  .plan. plan-player .text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    width: min-content;
  }
  .plan-player .text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .plan-player .text ul {
    width: max-content;
  }

  .r-player-info {
    display: none;
  }
  .plan .price del {
    position: absolute;
    left: 75%;
    width: 6.125rem;
    height: 3.125rem;
    line-height: 3.4rem;
    font-size: 1.7rem;
    margin: -2.125rem 1rem;
  }
  .plan .price del:before,
  .plan .price del:after {
    width: 2.3125rem;
    height: 2.3125rem;
  }
  .plan .price del:before {
    left: -0.5rem;
  }
  .plan .price del:after {
    right: -0.5rem;
  }
  .plan .price del > *:before {
    width: 0.375rem;
    height: 0.375rem;
  }
  .plan .price .big {
    font-weight: normal;
    font-size: 5.875rem;
    line-height: 6.3125rem;
  }
  .plan .period {
    display: none;
  }
  .plan .price .player-title {
    font-weight: normal;
    font-size: 3rem;
    line-height: 3.25rem;
    padding-top: 3rem;
  }
  .plan .price .player-info {
    flex-direction: column;
    display: inline-block;
    flex: 0 0 auto;
    padding-right: 2.5rem;
    margin-top: 2.5rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: rgba(255, 255, 255, 0.5);
  }
  .plan .price .small {
    font-size: 1.25rem;
    line-height: 2.125rem;
    margin: 0 0 0 2rem;
  }
  .plan ul {
    display: flex;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.875rem;
    letter-spacing: 0.02em;
    margin-left: 0;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .plan li {
    text-align: left;
    padding: 0 0 0 3.125rem;
    margin-left: 1.09375rem;
    margin-right: 1.09375rem;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  .plan li svg {
    width: 1.875rem;
    height: 1.875rem;
  }
  .plan-player ul {
    display: flex;
    flex-direction: column;
    margin-left: 1.375rem;
    padding-top: 2.2rem;
  }
  .big-text {
    padding-top: 5.625rem;
    padding-bottom: 0;
    font-size: 3.5rem;
    line-height: 1.1;
    max-width: 69.813rem;
  }
  .big-text-download {
    width: 100%;
    color: #ff721c;
    cursor: pointer;
  }
  .checkout {
    padding-top: 5.625rem;
    padding-bottom: 3.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .checkout .l {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50.16%;
  }
  .checkout .r {
    margin-top: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.75%;
  }
  .checkout-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 1.5rem;
    line-height: 1.625rem;
    margin-bottom: 3.125rem;
  }
  .checkout-title h2,
  .checkout-title .h2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 3rem;
    line-height: 3.25rem;
    margin: 0;
  }
  .checkout-title .email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: right;
    margin: 0 0 0.3125rem 1.875rem;
  }
  .checkout-plans .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .checkout-plans .plan .text {
    position: relative;
    padding-left: 20rem;
    padding-top: 5.625rem;
  }
  .checkout-plans .plan .time {
    display: none;
  }
  .checkout-plans .plan .price {
    position: absolute;
    top: 0.625rem;
    left: 0;
    height: auto;
  }
  .checkout-plans .plan ul {
    display: block;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    text-align: left;
    margin-left: 0;
    /*margin-right: -6.25rem;*/
    padding: 0;
  }
  .checkout-plans .plan li {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.375rem;
  }
  .checkout .code {
    margin-top: 4.875rem;
    margin-bottom: 3.75rem;
  }
  .checkout .code .input input {
    font-size: 2.25rem;
    line-height: 2.4375rem;
    padding-bottom: 1.25rem;
  }
  .checkout .code .btn {
    min-width: 15.625rem;
    font-size: 0.875rem;
    line-height: 0.9375rem;
    height: 4.375rem;
  }
  .checkout .code .input-info {
    margin-top: 1.875rem;
    font-size: 0.875rem;
    line-height: 0.9375rem;
  }
  .checkout .total {
    margin-bottom: 3.75rem;
    font-size: 1.5rem;
    line-height: 1.5;
    letter-spacing: normal;
  }
  .checkout .total li.big {
    font-size: 2.25rem;
    line-height: 2.4375rem;
    margin-top: 3.125rem;
  }
  .checkout .submit-info {
    text-align: center;
    margin-top: 1.875rem;
  }
  .checkout-done {
    padding-top: 5.625rem;
    padding-bottom: 6.875rem;
    font-size: 2.25rem;
    line-height: 1.5;
    letter-spacing: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .checkout-done .l {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 62.36%;
  }
  .checkout-done .r {
    margin-top: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 29.39%;
  }
  .checkout-done h2,
  .checkout-done .h2 {
    font-size: 2.25rem;
    line-height: 2.4375rem;
    margin-top: 6.25rem;
    margin-bottom: 2.5rem;
  }
  .checkout-done .info {
    margin-top: 3.25rem;
    margin-bottom: 6.375rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2.25rem;
    line-height: 2.4375rem;
  }
  .checkout-done .info li {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
  }
  .checkout-done .info li:not(:first-child) {
    margin-left: 1.25rem;
  }
  .checkout-done .info .txt {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 5.625rem;
  }
  .checkout-done .info strong {
    font-size: 1rem;
    line-height: 1.0625rem;
    letter-spacing: 0.02em;
  }
  .checkout-done .info .copy {
    width: 4.375rem;
    height: 4.375rem;
  }
  .checkout-done .info .copy svg {
    width: 1.875rem;
    height: 1.875rem;
    margin: -0.9375rem;
  }
  .checkout-done .links > *:not(:first-child) {
    margin-left: 1.25rem;
  }
  .checkout-done .links .btn {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
  .checkout-done .r ul {
    margin-top: 2.5rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
    letter-spacing: 0.02em;
  }
  .checkout-done .r li {
    padding: 0 0 0 3.125rem;
    margin-top: 1.25rem;
  }
  .checkout-done .r li svg {
    width: 1.875rem;
    height: 1.875rem;
  }
  .personal-head-email {
    font-size: 1.875rem;
    line-height: 2.5rem;
    margin-bottom: 5rem;
  }
  .license-card {
    margin-bottom: 0.8125rem;
  }
  .license-card.with-btn {
    padding-bottom: 0;
  }
  .license-card:not(:first-child) {
    margin-top: 8.125rem;
  }
  .license-card .head {
    font-size: 1.125rem;
    line-height: 1.875rem;
    padding-bottom: 2.5rem;
    margin-bottom: 3.125rem;
  }
  .license-card .head h2,
  .license-card .head .h2 {
    font-size: 2.25rem;
    line-height: 2.9375rem;
  }
  .license-card .head .btn {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    min-width: 15.625rem;
    margin: 0 0 0 2.5rem;
    font-size: 0.875rem;
    line-height: 0.9375rem;
    height: 4.375rem;
  }
  .license-card .info {
    font-size: 1.5rem;
    line-height: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .license-card .info li {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 31.25rem;
  }
  .license-card .info li:not(:first-child) {
    margin-top: 0;
    margin-left: 1.25rem;
  }
  .license-card .info strong {
    font-size: 1rem;
    line-height: 1.0625rem;
    letter-spacing: 0.02em;
    margin-bottom: 0.875rem;
  }
  .license-card .info .txt {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 4.375rem;
  }
  .license-card .info .copy {
    width: 4.375rem;
    height: 4.375rem;
    right: 0;
  }
  .license-card .info .copy svg {
    width: 1.875rem;
    height: 1.875rem;
    margin: -0.9375rem;
  }
  .modal {
    padding: 70px 130px;
    overflow: hidden;
  }
  .modal .image-viewer .fullview {
    height: 70vh;
  }

  .image-viewer .preview {
    margin-top: 80px;
  }

  .page-order-history .side-menu {
    width: 22rem;
  }
  .page-order-history .page-content {
    width: calc(100% - 22rem);
  }
  .order-history-title {
    margin-top: -1.5rem;
    margin-bottom: 1rem;
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .order-history-table {
    margin-top: 0;
    margin-bottom: 2.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--bg-borders);
  }
  .order-history-top-row {
    display: flex;
    margin-bottom: 2.5rem;
    padding: 1.85rem 0;
    border-bottom: 1px solid var(--bg-borders);
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .order-history-row {
    flex-wrap: nowrap;
    margin-bottom: 2.19rem;
    font-size: 1.25rem;
    line-height: 3.15rem;
    border-bottom: none;
  }
  .order-history-col {
    margin-bottom: 0;
    margin-right: 2.5rem;
  }
  .order-history-col:last-child {
    margin-right: 0;
  }
  .order-history-col.col-1 {
    width: 44%;
  }
  .order-history-col.col-2 {
    width: 14%;
  }
  .order-history-col.col-3 {
    width: 14%;
    text-align: left;
  }
  .order-history-col.col-4 {
    width: 14%;
  }
  .order-history-col.col-5 {
    width: 14%;
  }
  .order-history-col.col-4,
  .order-history-col.col-5 {
    text-align: right;
  }
  .order-mobile-label {
    display: none;
  }
  .order-history-row .copy {
    width: 3.15rem;
    height: 3.15rem;
  }

  .survey {
    left: 3.125rem;
  }
  .survey-popup {
    display: block;
    width: 29rem;
    padding: 1.87rem 2.2rem;
  }
  .survey-mobile-popup {
    display: none;
  }
  .survey-content {
    margin-bottom: 1.565rem;
  }
  .survey-icon {
    width: 3.75rem;
    height: 3.75rem;
  }
  .survey-text-container {
    margin-left: 1.25rem;
  }
  .survey-title {
    margin-bottom: 0.3rem;
    color: var(--accent);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.125rem;
  }
  .survey-text {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  .survey-close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }

  .form-row.settings-row {
    flex-direction: row;
  }
  .flag-label {
    flex-direction: column;
    padding: 2rem 3rem;
    margin-right: 2rem;
  }
  .flag-label img {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .spinner-licenses {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-50%);
  }

  .password-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .setup-config {
    scroll-margin-top: 4rem;
  }

  .cookie-banner {
    max-width: 46rem;
    bottom: 1.9rem;
    left: 50%;
    transform: translateX(-50%);
    height: 7rem;
    display: flex;
    font-size: 0.9rem;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.1rem 0 1.5rem;
  }

  .cookie-banner > .btn {
    width: auto;
    margin: 0 0 0 1rem;
  }

  .previous-versions {
    display: flex;
    flex-direction: row;
  }
  .previous-versions .title {
    font-size: 3rem;
    line-height: 52px;
  }
  .previous-versions .subtitle {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.5rem;
  }
  .previous-versions .agreement {
    font-size: 1rem;
  }

  .previous-versions .left {
    width: 60%;
    margin-right: 6rem;
  }

  .previous-version-item .item-title {
    font-size: 2.25rem;
  }

  .previous-version-item .item-sup {
    font-size: 1rem;
    color: #0de7b2;
    margin-left: 1.5rem;
    position: relative;
    top: -1rem;
  }

  .previous-version-item .item-links {
    display: flex;
    align-items: center;
  }

  .previous-version-item .item-links > a:nth-child(1) {
    padding: 1.25rem 0;
    font-size: 1.5rem;
  }

  .previous-version-item .item-links > a:nth-child(2) {
    padding: 1.25rem 0;
    font-size: 1.5rem;
  }

  .previous-versions .right .col > p {
    font-size: 1.25rem;
  }

  .previous-versions .left {
    border: 0;
  }

  .latest a {
    display: flex;
    align-items: center;
  }

  .latest a > svg {
    transform: scale(0.9);
  }
}

@media only screen and (min-width: 1600px) {
  .content {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    padding: 14.25rem 0 3.125rem;
  }
  .menu-lang {
    padding-left: 0;
  }
  .head-login-links,
  .head-user {
    padding-right: 0;
  }
  .headline {
    padding: 0;
  }
  .index-content {
    overflow: visible;
  }
  .top-index {
    margin: -16rem -3.125rem 0;
  }
  .top-index .top {
    padding-top: 16rem;
    padding-left: 3rem;
  }
  .top-index video {
    width: 110%;
    left: -5%;
  }
  .top-index .text {
    max-width: 41rem;
    font-size: 1.25rem;
  }
  .footer {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    padding: 6.25rem 0 6.25rem 0;
  }
  .survey {
    width: 100%;
    max-width: 1520px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .survey-popup {
    padding: 1.87rem 2.2rem;
  }
}

@media only screen and (min-width: 1920px) {
  :root {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1920px) {
  .download-now {
    margin-left: -12.2rem;
    margin-right: -12.2rem;
  }
}

@media only screen and (min-width: 1921px) {
  .download-now {
    margin-left: -22.2rem;
    margin-right: -22.2rem;
  }
}

@media only screen and (min-width: 2231px) {
  .download-now {
    margin-left: -32.2rem;
    margin-right: -32.2rem;
  }
}

.license-success-icon-container {
  fill: #0de7b2;
}

.buyers-information-container {
  display: flex;
  flex-direction: column;
}
.btn-wrap {
  width: 100%;
}
.buyers-information-container .buyers-information-title {
  padding-bottom: 3.125rem;
  margin-bottom: 3.125rem;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  border-bottom: 0.063rem solid rgba(255, 255, 255, 0.1);
  font-size: 2.25rem;
  line-height: 2.438rem;
}

.payment-for-license {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2.125rem;
  color: #fff;
}

.agree-by-clicking-container {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.938rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}
.agree-by-clicking-download {
  margin-bottom: 0.625rem;
}
.agree-by-clicking-redirect {
  color: var(--accent);
}
.policy-bold {
  font-weight: bold;
  color: var(--accent);
}
.download-policy-bold {
  color: var(--green);
}

.back-to-licenses {
  display: flex;
  align-items: center;
}

.licenseBuyRight {
  margin-top: 3.75rem;
}

.period-of-license {
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.188rem;
}

.license-months {
  margin-top: 0.938rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.563rem;
  line-height: 2rem;
  /* identical to box height, or 128% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #fff;
}

.period-of-license {
  margin-top: 3.125rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.188rem;
}

.plan-ul {
  margin-top: 1.563rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.plan-ul li {
  margin: 1.563rem 0 0;
}

@media only screen and (min-width: 1600px) {
  .black-friday {
    padding: 2% 22% 2% 2%;
  }

  .black-friday-discount,
  .black-friday-code,
  .tiger-logo-friday {
    transform: translateY(10%);
  }

  .black-friday-title.black-friday-title {
    transform: translateY(12%);
  }
}

@media only screen and (min-width: 1020px) {
  .black-friday {
    background: url(/img/black-friday.jpg) no-repeat 100% 100%;
    background-size: contain;
    flex-direction: row;
    align-items: center;
    padding: 2% 20% 2% 4%;
    gap: 40px;
    height: auto;
  }

  .black-friday-internal-page {
    transform: translateY(-50%);
    margin-bottom: 0;
  }

  .tiger-logo-friday {
    width: 20%;
  }

  .black-friday-discount {
    font-size: 1.5rem;
    padding: 0.5% 2% 0.3% 2%;
    border-radius: 18%/50%;
    position: static;
    top: auto;
    height: auto;
  }

  .black-friday-title {
    white-space: nowrap;
    display: block;
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
    transform: translateY(7%);
  }

  .black-friday-title-mobile {
    display: none;
  }

  .black-friday-code {
    font-size: 1.5rem;
    padding: 1.5% 2.7% 0 2.7%;
    border-radius: 15%/50%;
    height: auto;
    position: relative;
    top: auto;
    left: auto;
  }

  .black-friday-code::after {
    font-size: 0.8rem;
    font-weight: 100;
    top: 5px;
  }
}
